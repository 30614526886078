import { Skeleton } from '@mui/material';
import React from 'react';
import { PastDataNavigation } from 'src/pages/home/modules/patient2/components/patientDetails/moreDetails/pastData/pastDataPDFviewer/pastDataNavigation';

export const TableSkelton = () => {
  return (
    <table className="table table-bordered">
      <thead style={{ backgroundColor: `transparent`, color: `black` }}>
        <tr>
          <th>Period</th>
          <th>Month</th>
          <th>Month</th>
          <th>Month</th>
          <th>Month</th>
          <th>Month</th>
          <th>Month</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 7 }, (_, i) => (
          <tr key={`rectangle-${i}`}>
            <td>
              <Skeleton height={20} width={60} variant="rectangular" />
            </td>
            <td>
              <Skeleton height={20} width={60} variant="rectangular" />
            </td>
            <td>
              <Skeleton height={20} width={60} variant="rectangular" />
            </td>
            <td>
              <Skeleton height={20} width={60} variant="rectangular" />
            </td>
            <td>
              <Skeleton height={20} width={60} variant="rectangular" />
            </td>
            <td>
              <Skeleton height={20} width={60} variant="rectangular" />
            </td>
            <td>
              <Skeleton height={20} width={60} variant="rectangular" />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={`7`}>
            <PastDataNavigation />
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
