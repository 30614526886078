import React, { useState } from 'react';
import { PulseSpinner } from '../../../../../../common/components/spinner/spinner';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { VERIFY_PASSWORD_CONFIRMATION, postRequest, RESET_PASSWORD_CONFIRMATION } from '../../../../../../../crud/crud';
import { useSelector } from 'react-redux';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { Grid } from '@mui/material';

export const ResetPassword = ({ setSettingModalFlag }) => {
  const user = useSelector((state) => state?.user)
  const [loading, setLoading] = useState(false);
  const [prevPassword, setPrevPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState(false);
  const [resetPasswordConfirmationResponse, setResetPasswordConfirmationResponse] = useState(null);

  const previousPasswordCheck = () => {
    setLoading(true);
    const payload = {
      userId: user?.user?._id,
      password: prevPassword,
    };
    postRequest(VERIFY_PASSWORD_CONFIRMATION, payload)
      .then((response) => {
        setResetPasswordConfirmationResponse(response?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const resetPassword = () => {
    let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[.,#?!@$%^&*_]).{8,}$/;
    if (regex.test(newPassword) || regex.test(confirmPassword)) {
      if (prevPassword === newPassword) {
        errorToast(`The new password must be different from the previous password.`);
      } else if (newPassword === confirmPassword) {
        setLoading(true);
        const payLoad = {
          _id: user?.user?._id,
          _practiceId: user?._practiceId,
          userName: user?.userName,
          resetPassword: confirmPassword,
          secretToken: resetPasswordConfirmationResponse?.secretToken,
        };
        postRequest(RESET_PASSWORD_CONFIRMATION, { ...payLoad })
          .then((response) => {
            setLoading(false);
            successToast(response?.data.message);
            setSettingModalFlag(false)

          })
          .catch((err) => {
            setLoading(false);
            errorToast(err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error);
          });
      } else {
        setLoading(false);
        errorToast(`New and Confirm does not match`);
      }
    } else {
      setError(true);
    }
  };

  // FORM SUBMIT HANDLER
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className={!resetPasswordConfirmationResponse ? `col-6` : `d-none`}>
            <div className="input-group">
              <InputField
                size='small'
                fullWidth={true}
                onChange={(e) => setPrevPassword(e.target.value)}
                type="password"
                name="prevPassword"
                id="prevPassword"
                className="form-control"
                placeholder="Enter Previous Password"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <button
                  className={!resetPasswordConfirmationResponse ? `btn btn-outline-info` : `d-none`}
                  disabled={!prevPassword}
                  type="submit"
                  onClick={() => previousPasswordCheck()}
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>

        {resetPasswordConfirmationResponse ? (
          <div >
            {/*  for showing error */}

            <div className="col-12">
              <div
                className={!error ? `p-3 border border-1 w-50 shadow-sm` : `p-3 border border-1 w-50 shadow-sm text-danger`}
                style={{ background: !error ? `#f6fcfd` : `#ffd2d2` }}
              >
                <p>Please enter a password that meets the following criteria:</p>
                <p>-At least 8 characters.</p>
                <p>-A mix of both uppercase and lowercase letters.</p>
                <p>-A mix of letters and numbers.</p>
                <p>-Include at least one special character (e.g., ! @ # ? ).</p>
              </div>
            </div>
            <Grid container spacing={1} sx={{ pt: 1 }}>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <div >
                  <InputField
                    size='small'
                    label='New Password'
                    fullWidth={true}
                    onChange={(e) => setNewPassword(e.target.value)}
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    onFocus={() => setError(false)}
                  />

                </div></Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <div >
                  <InputField
                    label='Confirm Password'
                    size='small'
                    fullWidth={true}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    onFocus={() => setError(false)}
                  />

                </div></Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <div >
                  <button
                    disabled={!(newPassword && confirmPassword)}
                    type="submit"
                    className="btn btn-outline-info"
                    onClick={() => resetPassword()}
                  >
                    Reset Password
                  </button>
                </div></Grid>
            </Grid>
          </div>
        ) : null}
      </form>
      {loading && <PulseSpinner />}

    </div>
  );
}




