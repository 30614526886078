import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { errorToast } from 'src/pages/common/components/snackBar/toast';
import { momentWrapper } from 'src/momentWrapper';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SettingsIcon from '@mui/icons-material/Settings';
import { ListAllTickets } from './sysAdmin/list-all-tickets';
import { LIST_OF_TICKETS, postRequest } from '../../../../../../../crud/crud';
import { PulseSpinner } from '../../../../../../common/components/spinner/spinner';
import { CreateIssue } from './createIssue/CreateIssue';
import { ViewIssue } from './viewIssue/ViewIssue';
import { HtmlFormatter } from 'src/pages/common/components/HtmlFormatter';
import { ticketCenterIssueTypeTag } from 'src/utils';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';

const defaultStatusHandler = (defaultTicketStatus) => {
  if (!defaultTicketStatus || defaultTicketStatus === `opened`) {
    return 0;
  }
  return defaultTicketStatus === `closed` ? 1 : 0;
};

// Tabs Initialization
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export const Ticketing = () => {
  const { user } = useSelector((state) => state?.user);
  const defaultTicketStatus = useSelector((state) => state?.ticketsCenter?.latestIssueStatus);
  let defaultStatus = defaultStatusHandler(defaultTicketStatus);
  const [tabValue, setTabValue] = useState(defaultStatus);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [createIssueModelHandler, setCreateIssueModalHandler] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState(``);
  const [selectedIssueModalHandler, setSelectedIssueModalHandler] = useState(false);
  const [loading, setLoading] = useState(false);

  // List of Tickets
  const [listOfTickets, setListOfTickets] = useState([]);
  // Special Flag, to get the list of issues again
  const [issueListFlag, setIssueListFlag] = useState(false);
  // Total # of closed issues, Total # of opened issues
  const [totalClosedIssue, setTotalClosedIssue] = useState(0);
  const [totalOpenedIssue, setTotalOpenedIssue] = useState(0);

  // Special useEffect to set total number
  useEffect(() => {
    if (listOfTickets) {
      const closedIssues = listOfTickets.filter((ticket) => ticket.issueStatus === `closed`);
      setTotalClosedIssue(closedIssues.length);

      const openedIssues = listOfTickets.filter((ticket) => ticket.issueStatus === `opened`);
      setTotalOpenedIssue(openedIssues.length);
    }
  }, [listOfTickets]);

  // CALLING AFTER NEW ISSUE CREATED | ETC

  useEffect(() => {
    if (issueListFlag || (!issueListFlag && !user?.isSystemAdministrator)) {
      setLoading(true);
      postRequest(LIST_OF_TICKETS, {})
        .then((response) => {
          setLoading(false);
          setListOfTickets(response?.data?.tickets);
        })
        .catch((err) => {
          setLoading(false);
          errorToast(err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error);
        });
    }
  }, [issueListFlag, user]);

  const openSpecificIssueHandler = (issueId) => {
    setSelectedIssue(issueId);
    setSelectedIssueModalHandler(true);
  };
  // Tabs Initialization

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <Box sx={{ display: `flex`, flexDirection: `column`, padding: { xs: `30px 15px`, sm: `45px 30px` } }}>
      <h4>Issues</h4>
      {!user?.isSystemAdministrator ? (
        <Box sx={{ display: `flex`, justifyContent: `flex-end` }}>
          <button className="btn btn-sm btn-success" onClick={() => setCreateIssueModalHandler(true)}>
            New Issue
          </button>
        </Box>
      ) : null}

      {/* Ticket center, if user is System Admin */}
      {user?.isSystemAdministrator ? (
        <ListAllTickets />
      ) : (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: `divider` }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Open" {...a11yProps(0)} />
              <Tab label="Close" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <div style={{ width: `100%`, overflowY: `auto`, maxHeight: `58vh` }}>
              <ul className="list-group fa-padding">
                {listOfTickets?.map((ticket) => {
                  return (
                    ticket.issueStatus === `opened` && (
                      <li
                        key={ticket?._id}
                        className="list-group-item"
                        style={{ cursor: `pointer` }}
                        onClick={() => openSpecificIssueHandler(`${ticket._id}`)}
                      >
                        <div className="media">
                          <div style={{ display: `flex` }}>
                            <RedDot ticket={ticket} />
                            <SettingsIcon fontSize="small" className="mt-1" />
                          </div>
                          <div className="media-body ml-1">
                            <div className="row" style={{ width: `75vw` }}>
                              <div className="col-10 d-inline-block text-truncate">
                                <HtmlFormatter title={ticket.issueTitle} />
                              </div>
                              <div className="col-2 text-truncate">
                                <p
                                  className="text-info px-0"
                                  style={{
                                    fontWeight: `700`,
                                    textTransform: `capitalize`,
                                  }}
                                >
                                  {ticket.created_by?.firstName} {ticket.created_by?.lastName}
                                </p>
                              </div>
                            </div>
                            <div>
                              Issue Type {ticketCenterIssueTypeTag(ticket.issueType)}
                              <span className="ml-1">
                                Priority {ticket.issuePriority === `normal` && <span className="badge badge-primary p-2 rounded">Normal</span>}
                                {ticket.issuePriority === `urgent` && <span className="badge badge-success p-2 rounded">Urgent</span>}
                              </span>
                            </div>
                            <div>
                              <span className="text-success font-italic" style={{ fontSize: `0.7em` }}>
                                Opened : {ticket.issueStatus === `opened` && momentWrapper(ticket.createdAt).format(`MM/DD/YYYY hh:mm A`)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                  );
                })}
              </ul>
              {totalOpenedIssue === 0 && (
                <div className="text-center mt-5">
                  <h5>No issues found</h5>
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <div style={{ width: `100%`, overflowY: `auto`, maxHeight: `58vh` }}>
              <ul className="list-group fa-padding">
                {listOfTickets.map(
                  (ticket) =>
                    ticket.issueStatus === `closed` && (
                      <li
                        key={ticket?._id}
                        className="list-group-item"
                        style={{ cursor: `pointer` }}
                        onClick={() => openSpecificIssueHandler(`${ticket._id}`)}
                      >
                        <div className="media">
                          <RedDot ticket={ticket} />
                          <SettingsIcon fontSize="small" className="mt-1" />
                          <div className="media-body ml-1">
                            <div className="row" style={{ width: `75vw` }}>
                              <div className="col-10 d-inline-block text-truncate">
                                <HtmlFormatter title={ticket.issueTitle} />
                              </div>
                              <div className="col-2 text-truncate">
                                <p
                                  className="text-info px-0"
                                  style={{
                                    fontWeight: `700`,
                                    textTransform: `capitalize`,
                                  }}
                                >
                                  {ticket.created_by?.firstName} {ticket.created_by?.lastName}
                                </p>
                              </div>
                            </div>
                            <div>
                              Issue Type {ticketCenterIssueTypeTag(ticket.issueType)}
                              <span className="ml-1">
                                Priority {ticket.issuePriority === `normal` && <span className="badge badge-primary p-2 rounded">Normal</span>}
                                {ticket.issuePriority === `urgent` && <span className="badge badge-success">Urgent</span>}
                              </span>
                            </div>
                            <div>
                              <span className="text-danger font-italic" style={{ fontSize: `0.7em` }}>
                                Closed : {ticket.issueStatus === `closed` && momentWrapper(ticket.closedTicketDate).format(`MM/DD/YYYY hh:mm A`)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                )}
              </ul>
              {totalClosedIssue === 0 && (
                <div className="text-center mt-5">
                  <h5>No issues found</h5>
                </div>
              )}
            </div>
          </TabPanel>
        </Box>
      )}

      {createIssueModelHandler && (
        <CreateIssue
          createIssueModelHandler={createIssueModelHandler}
          setCreateIssueModalHandler={setCreateIssueModalHandler}
          setIssueListFlag={setIssueListFlag}
        />
      )}

      {selectedIssueModalHandler && (
        <ViewIssue
          selectedIssueId={selectedIssue}
          selectedIssueModalHandler={selectedIssueModalHandler}
          setSelectedIssueModalHandler={setSelectedIssueModalHandler}
          setIssueListFlag={setIssueListFlag}
        />
      )}
      {(user?.isSuperAdmin || user?.isProvider || user?.isClinicalStaff) && loading && <PulseSpinner />}
    </Box>
  );
};

const RedDot = ({ ticket }) => {
  const [shouldShowDot, setShouldShowDot] = useState(false);
  useEffect(() => {
    const handler = (data) => {
      if (data?._id === ticket?._id && data?.readBySysAdmin && !data?.readByUser) setShouldShowDot(true);
    };
    newSocket.on(`ticketStatusUpdated`, handler);
    if (ticket?.readBySysAdmin && ticket?.readByUser) setShouldShowDot(false);
    return () => {
      newSocket.off(`ticketStatusUpdated`, handler);
      setShouldShowDot(false);
    };
  }, [ticket]);
  console.log(shouldShowDot, `shouldShowDot`);
  return (ticket?.readBySysAdmin && !ticket?.readByUser) || shouldShowDot ? <span style={{ color: `red` }}>&#9679;</span> : null;
};
