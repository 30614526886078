import moment from 'moment';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SERVER_ADDRESS } from 'src/crud/axiosClient';
import { GET_EXPIRY_TIME, postRequest, REFRESH_AUTH_TOKEN } from 'src/crud/crud';
import { setAuth } from 'src/redux/action/authTokenHandler';
import { setUserHandler } from 'src/redux/action/setUserHandler';
import { store } from 'src/redux/store';

let timer;

export const JwtExpWrapper = ({ children }) => {
    const { user: { user } } = useSelector((state) => state);
    const { tokenValidityTime } = user || false;
    const dispatch = useDispatch();

    useEffect(() => {
        const { user: { user } } = store.getState()
        const { refreshToken } = user
        if (tokenValidityTime) {
            timer = setInterval(() => {
                postRequest(REFRESH_AUTH_TOKEN, {
                    refreshToken: refreshToken
                }).then((res) => {
                    const { data = {
                        freshToken: ``,
                        resExp: 0
                    } } = res
                    const { token: freshToken, tokenValidityTime: resExp } = data
                    if (freshToken) {
                        dispatch(setAuth(freshToken))
                        dispatch(setUserHandler({ ...user, tokenValidityTime: resExp }))
                    }
                }).catch(() => {
                    clearInterval(timer)
                })
            }, tokenValidityTime)
        }
        return () => clearInterval(timer)
    }, [tokenValidityTime])

    useEffect(() => {
        postRequest(SERVER_ADDRESS + GET_EXPIRY_TIME, {
            timeZone: moment.tz.guess(),
        }).then((res) => {
            const { data: { newExpiryTime } } = res
            dispatch(setUserHandler({ ...user, tokenValidityTime: newExpiryTime }))
        }).catch(() => {
            console.log(`error`)
        })
    }, [])

    return (
        <>{children}</>
    )
}