import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import css from './advance-filter.module.css';
import { checkInputsHelper, dynamicObjCreator } from 'src/utils';
import { Grid, MenuItem, TextField } from '@mui/material';
import { momentWrapper } from 'src/momentWrapper';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';

export const AdvanceFilter = ({ setSearchFilters, setIssueListFlag, resetPagination }) => {
  const [visible, setVisible] = useState(false);
  const [practiceName, setPracticeName] = useState(``);
  const [firstName, setFirstName] = useState(``);
  const [lastName, setLastName] = useState(``);
  const [issueStatus, setIssueStatus] = useState(``);
  const [issuePriority, setIssuePriority] = useState(``);
  const [issueType, setIssueType] = useState(``);
  const [startDate, setStartDate] = useState(``);
  const [endDate, setEndDate] = useState(``);
  const data = useSelector((state) => state?.ticketsCenter?.data);

  const validator = (type) => {
    if (type !== `filter`) {
      return (
        startDate == `` &&
        endDate == `` &&
        practiceName == `` &&
        firstName == `` &&
        lastName == `` &&
        issueStatus == `` &&
        issuePriority == `` &&
        issueType == ``
      );
    } else {
      return (
        startDate !== `` ||
        endDate !== `` ||
        practiceName !== `` ||
        firstName !== `` ||
        lastName !== `` ||
        issueStatus !== `` ||
        issuePriority !== `` ||
        issueType !== ``
      );
    }
  };

  const fetchTickets = (filterVal) => {
    setSearchFilters(filterVal);
    setIssueListFlag((p) => !p);
    resetPagination();
  };

  const resetHandler = () => {
    let check = checkInputsHelper([practiceName, firstName, lastName, issueStatus, issuePriority, issueType, startDate, endDate], data?.length);
    const resetValues = () => {
      setPracticeName(``);
      setFirstName(``);
      setLastName(``);
      setIssueStatus(``);
      setIssuePriority(``);
      setIssueType(``);
      setStartDate(``);
      setEndDate(``);
      fetchTickets({});
    };

    if (!check) {
      resetValues();
      resetPagination();
    }
  };

  const filterHandler = () => {
    const searchFilters = dynamicObjCreator({
      startDate: startDate ? momentWrapper(startDate)?.toISOString() : ``,
      endDate: endDate ? momentWrapper(endDate)?.toISOString() : ``,
      practiceName: practiceName,
      firstName: firstName,
      lastName: lastName,
      issueStatus: issueStatus,
      issuePriority: issuePriority,
      issueType: issueType,
    });
    if (validator(`filter`)) {
      fetchTickets({ ...searchFilters });
    }
  };

  return (
    <div>
      <div className="collapse" id="advanceFilterCard">
        <div
          style={{
            backgroundColor: `white`,
            padding: `5% 2%`,
            margin: `2% 0%`,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <DatePickerCustomize
                onChange={(date) => setStartDate(date?.toString())}
                dateInputValue={startDate || null}
                step1RespironicsNumber={false}
                pickerProps={{
                  label: `Start Date`,
                  openTo: `month`,
                  style: { width: `100%` },
                  views: [`year`, `month`, `day`],
                  inputFormat: `YYYY/MM/DD`,
                  InputLabelProps: {
                    shrink: true,
                  },
                  fullWidth: true,
                  size: `medium`,
                }}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <DatePickerCustomize
                dateInputValue={endDate || null}
                step1RespironicsNumber={false}
                pickerProps={{
                  label: `End Date`,
                  openTo: `month`,
                  style: { width: `100%` },
                  views: [`year`, `month`, `day`],
                  inputFormat: `YYYY/MM/DD`,
                  InputLabelProps: {
                    shrink: true,
                  },
                  fullWidth: true,
                  size: `medium`,
                }}
                onChange={(date) => setEndDate(date?.toString())}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextField
                InputLabelProps={{
                  style: {
                    left: `-2%`,
                  },
                }}
                fullWidth
                autoComplete="off"
                onChange={(event) => setFirstName(event.target.value)}
                value={firstName}
                type="text"
                label="First Name"
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextField
                InputLabelProps={{
                  style: {
                    left: `-2%`,
                  },
                }}
                fullWidth
                autoComplete="off"
                onChange={(event) => setLastName(event.target.value)}
                value={lastName}
                type="text"
                label="Last Name"
                id="outlined-basic"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextField
                InputLabelProps={{
                  style: {
                    left: `-2%`,
                  },
                }}
                select
                fullWidth
                name="issueType"
                label="Issue Type"
                onChange={(event) => setIssueType(event?.target?.value)}
                value={issueType}
              >
                <MenuItem defaultValue value="">
                  <em>Select</em>
                </MenuItem>
                <MenuItem value="import/add patient"> Import/Add patient</MenuItem>
                <MenuItem value="dashboard">Dashboard</MenuItem>
                <MenuItem value="patients">Patients</MenuItem>
                <MenuItem value="messenger">Messenger</MenuItem>
                <MenuItem value="telemedicine">Telemedicine</MenuItem>
                <MenuItem value="analytics">Analytics</MenuItem>
                <MenuItem value="administration">Administration</MenuItem>
                <MenuItem value="billing">Billing</MenuItem>
                <MenuItem value="request new feature">Request new feature</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextField
                select
                InputLabelProps={{
                  style: {
                    left: `-2%`,
                  },
                }}
                fullWidth
                name="issueStatus"
                label="Issue Status"
                onChange={(event) => setIssueStatus(event.target.value)}
                value={issueStatus}
              >
                <MenuItem defaultValue value="">
                  <em>Select</em>
                </MenuItem>
                <MenuItem value="opened">Opened</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextField
                InputLabelProps={{
                  style: {
                    left: `-2%`,
                  },
                }}
                select
                label="Issue Priority"
                fullWidth
                name="issuePriority"
                onChange={(event) => setIssuePriority(event.target.value)}
                value={issuePriority}
              >
                <MenuItem defaultValue value="">
                  <em>Select</em>
                </MenuItem>
                <MenuItem value="normal">Normal</MenuItem>
                <MenuItem value="urgent">Urgent</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </div>
      </div>
      {window.innerWidth > 768 ? (
        <div className="d-flex justify-content-between align-items-center">
          <h4 style={{ fontWeight: `400`, marginLeft: `3%` }}>Ticket Center</h4>
          <div className="d-flex justify-content-end">
            <button className={visible ? css.customResetButton : `d-none`} type="button" onClick={resetHandler}>
              <i className="fa fa-times"></i> Reset
            </button>
            <button className={visible ? css.customApplyButton : `d-none`} type="button" onClick={filterHandler}>
              <i className="fa fa-search"></i> Apply
            </button>
            <button
              className={css.customFilterButton}
              type="button"
              data-toggle="collapse"
              data-target="#advanceFilterCard"
              onClick={() => setVisible(!visible)}
            >
              <i className="fa fa-filter"></i> Filter
            </button>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-between align-items-center">
          <h4 style={{ fontWeight: `400`, fontSize: 15, marginLeft: `6%` }}>Ticket Center</h4>
          <div className="d-flex justify-content-end" style={{ marginRight: `6%` }}>
            <button className={visible ? null : `d-none`} type="button" onClick={resetHandler}>
              <i className="fa fa-times" style={{ color: `#c41717` }}></i>
            </button>
            <button className={visible ? null : `d-none`} type="button" onClick={filterHandler}>
              <i className="fa fa-search" style={{ color: `green` }}></i>
            </button>
            <button type="button" data-toggle="collapse" data-target="#advanceFilterCard" onClick={() => setVisible(!visible)}>
              <i className="fa fa-filter" style={{ color: `#1699C5` }}></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
