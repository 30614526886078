import { printInBrowser } from "src/utils";

/* eslint-disable no-case-declarations */
const {
  UPDATE_USER_CHATID_COVERSATION,
  LIST_OF_CONVERSATION,
  SOCKET,
  RECENT_MESSAGE,
  SELECTED_USER,
  INDIVIDUAL_USER,
  RESET_REDUX,
  UPDATE_ONLINE_STATUS,
  UPDATE_USER_CHAT_ID,
  UPDATE_MESSAGE_READ_STATUS,
  GET_PATIENT_LIST,
} = require(`./messengerTypes`);

const initialState = {
  conversationList: [],
  socket: null,
  recentMessage: ``,
  selectedUser: {},
  individualUser: {},
  listOfPatients: [],
};

export const messengerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_OF_CONVERSATION:
      return {
        ...state,
        conversationList: [...action.payload],
      };
    case GET_PATIENT_LIST:
      return {
        ...state,
        listOfPatients: [...action.payload],
      };
    case UPDATE_USER_CHATID_COVERSATION:
      const targetIndex = state.conversationList.filter((list) => {
        return action.payload._receiverId === list._id;
      });

      if (targetIndex[0]) {
        targetIndex[0]._chatRoomId = action.payload._chatRoomId;
      }
      const updatedTarget = state.conversationList.map((list) => {
        return list._id === action.payload._receiverId ? targetIndex[0] : list;
      });

      return {
        ...state,
        conversationList: updatedTarget,
      };

    case SOCKET: {
      return {
        ...state,
        socket: action.payload,
      };
    }
    case RECENT_MESSAGE:
      let individual = state.individualUser;
      let recentMsgdate = action.payload?.date;
      if (action.payload?.type) {
        printInBrowser({ key: `valueChecking`, value: action.payload?.id })
        individual[`${action.payload.id}`][`type`] = action.payload?.type
      } else {
        printInBrowser({ key: `valueChecking`, value: action.payload?.id })
        individual[`${action.payload.id}`][`type`] = ``
      }
      individual[`${action.payload.id}`].lastMessage = action.payload.message;

      if (action?.payload?._chatRoomId) {
        individual[`${action.payload.id}`]._chatRoomId = action.payload._chatRoomId;
      }
      if (!action?.payload?.isMessageRead) {
        individual[`${action.payload.id}`].isMessageRead = action.payload.isMessageRead;
        individual[`${action.payload.id}`].date = recentMsgdate;
      }
      return {
        ...state,
        individualUser: { ...individual },
      };
    case UPDATE_ONLINE_STATUS:
      let currentUser = state.individualUser;
      if (currentUser[`${action.payload.userId}`] && currentUser[`${action.payload.userId}`]?.practiceId === action.payload?.practiceId) {
        currentUser[`${action.payload.userId}`].isOnline = action.payload.isOnline;
      }
      return {
        ...state,
        individualUser: { ...currentUser },
      };
    case UPDATE_USER_CHAT_ID:
      let _user = state.individualUser;

      if (_user[`${action.payload._receiverId}`]) {
        _user[`${action.payload._receiverId}`]._chatRoomId = action.payload._chatRoomId;
      }
      return {
        ...state,
        individualUser: { ..._user },
      };
    case UPDATE_MESSAGE_READ_STATUS:
      let _selectedUser = state.individualUser;
      if (_selectedUser[`${action.payload.userId}`]) {
        _selectedUser[`${action.payload.userId}`].isMessageRead = action.payload.isMessageRead;
      }
      return {
        ...state,
        individualUser: { ..._selectedUser },
      };
    case SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case INDIVIDUAL_USER:
      return {
        ...state,
        individualUser: { ...action.payload },
      };
    case RESET_REDUX:
      return {
        conversationList: [],
        recentMessage: ``,
        selectedUser: ``,
        individualUser: {},
        listOfPatients: [],
      };
    default:
      return state;
  }
};
