import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import css from './listAllPatients.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { momentWrapper } from 'src/momentWrapper';
import { PuffSpinner } from '../../../../../common/components/spinner/puff/puff';
import InfiniteScroll from 'react-infinite-scroll-component';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
import { Box, Collapse, Paper, Zoom } from '@mui/material';
import {
  setPatientsFilters,
  handleTimer,
  saveTimerUser,
  handleSelectedPatient,
  communcicationWithPatient,
  setQueryParamsFilters,
  handleSelectedPatientFromDashboard,
  handleReportIdFromReadyToSignReport,
  switchPatient,
  emptyPatientData,
  setSelectedPatientReport,
  setAllMessages,
  changeAccumulatedTime,
} from '../../../../../../redux/patient/patientActions';
import {
  capitalizeFirstLetter,
  isDme,
  timeLogAddOnSwitchPatientAndSorting,
  emitEventOnLessThan5s,
  printInBrowser,
  doIfArr,
  returnIfArr,
} from 'src/utils';
import { store } from 'src/redux/store';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { errorToast } from 'src/pages/common/components/snackBar/toast';
import { MuiBtn } from 'src/pages/common/components/Button/CustomBtn';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { BadgePill } from 'src/pages/common/components/BadgePill/BadgePill';


let patients = null;
let patientsBillerRole = null;

export const ListAllPatients = ({ isAllowToClickOnPatient, setCurrentTab, videoCallStatus }) => {
  const user = useSelector((state) => state?.user?.user);
  const primaryAndSecondaryChoice = useSelector((state) => state?.user?.primaryAndSecondaryChoice);
  const dispatch = useDispatch();
  const timer = useSelector((state) => state?.patient?.timer);
  const patientData = useSelector((state) => state?.patient?.patientData);
  const patientsList = useSelector((state) => state?.patient?.patientsList);
  const { startTime } = useSelector((state) => state.patient.timer);
  const {
    timer: { audioCall: isUserOnAudCall = false, videoCall: isUserOnVidCall = false },
  } = useSelector((state) => state);
  const patientFilters = useSelector((state) => state?.patient?.patientsFilters);
  // const audioCallStatus = useSelector((state) => state.timer.audioCall);
  //   Filters Hooks
  //   Filters Hooks
  // audioCall
  const [hasMore, setHasMore] = useState(true);
  const [notMonitored, setNotMonitored] = useState();
  const [zeroToTen, setZeroToTen] = useState(``);
  const [tenToTwenty, setTenToTwenty] = useState(``);
  const [greaterThanTwenty, setGreaterThanTwenty] = useState(``);
  const [sentToMd, setSentToMd] = useState(``);
  const [veryHigh, setVeryHigh] = useState(``);
  const [high, setHigh] = useState(``);
  const [moderate, setModerate] = useState(``);
  const [low, setLow] = useState(``);
  const [adherent, setAdherent] = useState(``);
  const [nonAdherent, setNonAdherent] = useState(``);
  const [noDataFound, setNoDataFound] = useState(``);
  const [online, setOnline] = useState(``);
  const [offline, setOffline] = useState(``);
  const [last5Days, setLast5Days] = useState(false);
  const [active, setActive] = useState(``);
  const isPatientAdded = useSelector((state) => state?.patient?.isAdded);
  const [inactive, setInactive] = useState(``);
  const [autoReactivation, setAutoReactivation] = useState(``);
  const [recurringReactivation, setRecurringReactivation] = useState(``);
  const [selectId, setSelectId] = useState(``);
  const patientsListRef = useRef(null);
  // Filters hooks end
  const [persistFilteredPayload, setPersistFilteredPayload] = useState({
    sortField: `lastName`,
    sortOrder: `ASC`,
    pageNumber: `1`,
    pageSize: `30`,
    searchFilters: {
      monitoringTime: [],
      tier: [],
      isAdherent: [],
      online: [],
      status: [`active`],
      enrollmentDate: [],
      recurringMonth: [],
      assignedAs: [...returnIfArr(patientFilters?.searchFilters?.assignedAs)],
    },
  });
  const { searchFilters } = persistFilteredPayload;
  const { recurringMonth } = searchFilters;
  const { assignedAs } = searchFilters;

  // const { value: recurringReactivationValue, open: openRecurringReactivation } = recurringMonth
  const dispatchRedux = useDispatch();
  const [sortKey, setSortKey] = useState(`lastName`);
  const [sortOrder, setSortOrder] = useState(1);
  //Pagination infinite values
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [opened, setOpened] = useState(false);
  const changeFiltersView = useCallback(() => {
    setOpened((p) => !p);
  }, []);

  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, `\\$&`);
    let regex = new RegExp(`[?&]` + name + `(=([^&#]*)|&|#|$)`),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return ``;
    return decodeURIComponent(results[2].replace(/\+/g, ` `));
  };

  const checkTimeToggle = (patient) => {
    dispatchRedux(switchPatient(true));
    dispatchRedux({ type: `ISSENT`, payLoad: false });
    setSelectId(patient?._id);
    if (timer?.toggleButton && !isDme()) {
      const endTimeValue = momentWrapper().toISOString();
      dispatchRedux(handleTimer({ status: false }));
      const timeDiff = momentWrapper(endTimeValue).diff(startTime, `seconds`);
      const isChatting = store?.getState()?.patient?.isChatting;
      const descriptionForTimer = store?.getState()?.patient?.descriptionText;
      if (timeDiff > 5) {
        dispatchRedux(
          saveTimerUser({
            videoOrAudioOn: null,
            updateVideoorAudioLogStatus: null,
            callSuccess: null,
            statuss: isChatting ? descriptionForTimer : `View graphs and reports`,
            sources: `View Report`,
            callSelectedPatient: true,
            callSelectedPatientData: patient,
          })
        );
      } else {
        emitEventOnLessThan5s();
      }
      setCurrentTab(`msg`);

      dispatchRedux(handleSelectedPatient({ patient: patient }));
    } else {
      dispatchRedux(communcicationWithPatient({ chatting: false, text: `` }));
      dispatchRedux(handleSelectedPatient({ patient: patient }));
    }
  };
  const location = useLocation();

  // default checkbox on load of app from redux
  const defaultFilterSelect = () => {
    const urlParams = getParameterByName(`show`);

    if (urlParams === `all`) {
      const searchFiltersCopy = {};
      const copyFilteredobj = {};
      searchFiltersCopy[`monitoringTime`] = [];
      searchFiltersCopy[`tier`] = [];
      searchFiltersCopy[`isAdherent`] = [];
      searchFiltersCopy[`online`] = [];
      searchFiltersCopy[`status`] = [`active`];
      searchFiltersCopy[`enrollmentDate`] = [];
      searchFiltersCopy[`assignedAs`] = primaryAndSecondaryChoice === `both` ? [`primary`, `secondary`] : [`${primaryAndSecondaryChoice}`]; // here the filter Add redirect from show all.
      copyFilteredobj[`searchFilters`] = searchFiltersCopy;
      copyFilteredobj[`pageNumber`] = 1;
      copyFilteredobj[`pageSize`] = 30;
      copyFilteredobj[`sortField`] = `lastName`;
      copyFilteredobj[`sortOrder`] = `ASC`;
      setActive(`active`);
      dispatch(setPatientsFilters(copyFilteredobj));
      setPersistFilteredPayload(copyFilteredobj);
      dispatchRedux(setQueryParamsFilters({ nextQueryParams: copyFilteredobj, scrollingParams: false }));
    } else {
      doIfArr(patientFilters?.searchFilters?.monitoringTime, () => {
        const monitoringFilters = patientFilters.searchFilters.monitoringTime;

        const filterMap = {
          "0": () => setNotMonitored("notMonitored"),
          "0,10": () => setZeroToTen("zeroToTen"),
          "10,20": () => setTenToTwenty("tenToTwenty"),
          "20": () => setGreaterThanTwenty("greaterThanTwenty"),
          "sentToMd": () => setSentToMd("sentToMd"),
        };

        monitoringFilters.forEach((filter) => {
          const key = filter.join(",");
          if (filterMap[key]) {
            filterMap[key]();
          }
        });

      });

      //Risk Stratification
      doIfArr(patientFilters?.searchFilters?.tier, () => {
        for (let filter of patientFilters.searchFilters.tier) {
          if (filter === `very high risk`) {
            setVeryHigh(`very high risk`);
          }
          if (filter === `high risk`) {
            setHigh(`high risk`);
          }
          if (filter === `moderate risk`) {
            setModerate(`moderate risk`);
          }
          if (filter === `low risk`) {
            setLow(`low risk`);
          }
        }
      });

      //Adherence
      doIfArr(patientFilters?.searchFilters?.isAdherent, () => {
        for (let filter of patientFilters.searchFilters.isAdherent) {
          if (filter) {
            setAdherent(`adherent`);
          }
          if (!filter) {
            setNonAdherent(`nonAdherent`);
          }
          if (filter === null) {
            setNoDataFound(`NO_DATA`);
          }
        }
      });

      // Status
      doIfArr(patientFilters?.searchFilters?.status, () => {
        for (let filter of patientFilters.searchFilters.status) {
          if (filter === `active`) {
            setActive(`active`);
          }
          if (filter === `inactive`) {
            setInactive(`inactive`);
          }
          if (filter === `autoReactivation`) {
            setAutoReactivation(`autoReactivation`);
          }
          if (filter === `recurringReactivation`) {
            setRecurringReactivation(`recurringReactivation`);
          }
        }
      });

      //  online/offline

      doIfArr(patientFilters?.searchFilters?.online, () => {
        for (let filter of patientFilters.searchFilters.online) {
          if (filter) {
            setOnline(`online`);
          }

          if (!filter) {
            setOffline(`offline`);
          }
        }
      });

      doIfArr(patientFilters?.searchFilters?.recurringMonth, () => {
        if (patientFilters.searchFilters.recurringMonth) {
          recurringValueSetter(false, patientFilters?.searchFilters?.recurringMonth);
        }
      });

      //Monitoring Time
    }
  };
  // Filter functions and logic
  useEffect(() => {
    if ((location.patientId && !isPatientAdded) || (location.patientId && isPatientAdded)) {
      dispatchRedux(handleTimer({ status: false }));
      setSelectId(location.patientId);
      dispatchRedux(handleSelectedPatientFromDashboard(location.patientId, location?.shouldNotStartTimer));
    } else if ((location.reportId && !isPatientAdded) || (location.reportId && isPatientAdded)) {
      setSelectId(location.reportPatientID);
      dispatchRedux(handleReportIdFromReadyToSignReport(location)); //location-->param is sending complete object containaing all values e.g report id and report id
    } else if (
      (getParameterByName(`Adherence`) && isPatientAdded) ||
      (getParameterByName(`risk`) && isPatientAdded) ||
      (getParameterByName(`monitoring`) && isPatientAdded) ||
      (getParameterByName(`status`) && isPatientAdded) ||
      (getParameterByName(`Adherence`) && !isPatientAdded) ||
      (getParameterByName(`risk`) && !isPatientAdded) ||
      (getParameterByName(`monitoring`) && !isPatientAdded) ||
      (getParameterByName(`status`) && !isPatientAdded)
    ) {
      let adherent = getParameterByName(`Adherence`);
      let _risk = getParameterByName(`risk`);
      let _monitoring = getParameterByName(`monitoring`);
      let _status = getParameterByName(`status`);
      let copyFilteredobj = { ...persistFilteredPayload };
      const searchFiltersCopy = { ...persistFilteredPayload.searchFilters };
      let monitoringTimeArray = [];
      let tierArray = [];
      let isAdherentArray = [];
      let isOnlineArray = [];
      if (_monitoring) {
        if (_monitoring === `notMonitored`) {
          monitoringTimeArray.push([0]);
          setNotMonitored(`notMonitored`);
        }
        if (_monitoring === `zeroToTen`) {
          monitoringTimeArray.push([0, 10]);
          setZeroToTen(`zeroToTen`);
        }
        if (_monitoring === `tenToTwenty`) {
          monitoringTimeArray.push([10, 20]);
          setTenToTwenty(`tenToTwenty`);
        }
        if (_monitoring === `greaterThanTwenty`) {
          monitoringTimeArray.push([20]);
          setGreaterThanTwenty(`greaterThanTwenty`);
        }
        if (_monitoring === `sentToMd`) {
          monitoringTimeArray.push([`sentToMd`]);
          setSentToMd(`sentToMd`);
        }
      }
      if (_risk) {
        if (_risk === `veryHigh`) {
          tierArray.push(`very high risk`);
          setVeryHigh(`very high risk`);
        }
        if (_risk === `high`) {
          tierArray.push(`high risk`);
          setHigh(`high risk`);
        }
        if (_risk === `moderate`) {
          tierArray.push(`moderate risk`);
          setModerate(`moderate risk`);
        }
        if (_risk === `low`) {
          tierArray.push(`low risk`);
          setLow(`low risk`);
        }
      }
      if (adherent) {
        if (adherent === `adherent`) {
          isAdherentArray.push(true);
          setAdherent(`adherent`);
        }
        if (adherent === `nonAdherent`) {
          isAdherentArray.push(false);
          setNonAdherent(`nonAdherent`);
        }
        if (adherent === `NO_DATA`) {
          isAdherentArray.push(null);
          setNoDataFound(`NO_DATA`);
        }
      }
      if (_status) {
        if (_status == `online`) {
          isOnlineArray.push(true);
          setOnline(`online`);
        }
        if (_status == `offline`) {
          isOnlineArray.push(false);
          setOffline(`offline`);
        }
      }
      searchFiltersCopy.monitoringTime = monitoringTimeArray;
      searchFiltersCopy.tier = tierArray;
      searchFiltersCopy.isAdherent = isAdherentArray;
      searchFiltersCopy.online = isOnlineArray;
      searchFiltersCopy.status = [`active`];
      searchFiltersCopy.enrollmentDate = [];
      searchFiltersCopy[`assignedAs`] = primaryAndSecondaryChoice === `both` ? [`primary`, `secondary`] : [`${primaryAndSecondaryChoice}`];
      copyFilteredobj[`searchFilters`] = searchFiltersCopy;
      copyFilteredobj[`pageNumber`] = 1;
      copyFilteredobj[`pageSize`] = 30;
      dispatch(setPatientsFilters(copyFilteredobj));
      setActive(`active`);
      setPersistFilteredPayload(copyFilteredobj);
      dispatchRedux(setQueryParamsFilters({ nextQueryParams: copyFilteredobj, scrollingParams: false }));

      setPageNumber(1);
    } else if ((getParameterByName(`show`) === `all` && isPatientAdded) || (getParameterByName(`show`) === `all` && !isPatientAdded)) {
      defaultFilterSelect();
    } else {
      if (isPatientAdded || !isPatientAdded) {
        defaultFilterSelect();
        dispatchRedux(setQueryParamsFilters({ nextQueryParams: patientFilters, scrollingParams: false }));
      }
    }
  }, [location.patientId, location.reportId, isPatientAdded]);

  useEffect(() => {
    newSocket.on(`patientTimeLog`, (data) => {
      dispatchRedux(changeAccumulatedTime(data))
    })
  }, [])

  const sortHandler = (key) => {
    selectId && setSelectId(``);
    timeLogAddOnSwitchPatientAndSorting();
    selectId && setSelectId(``);
    const promise = new Promise((resolve) => {
      setSortKey(key);
      if (sortOrder === -1) {
        setSortOrder(1);
        resolve(1);
      } else {
        setSortOrder(-1);
        resolve(-1);
      }
    });
    promise
      .then((value) => {
        let copyFilteredobj = { ...patientFilters };
        // Set the obj values
        copyFilteredobj.sortField = key;
        copyFilteredobj.sortOrder = value === -1 ? `ASC` : value === 1 ? `DESC` : null;
        copyFilteredobj[`pageNumber`] = 1;
        copyFilteredobj[`pageSize`] = 30;
        // End
        setPersistFilteredPayload(copyFilteredobj);
        setPageNumber(1);
        dispatchRedux(setQueryParamsFilters({ nextQueryParams: copyFilteredobj, scrollingParams: false }));
        setHasMore(true);
      })
      .catch(() => { });
  };

  const fetchMoreData = () => {
    if (patientsList?.data?.patients?.length >= patientsList?.data?.pager?.totalRecords) {
      setHasMore(false);
      return;
    }

    //  Make call here
    const copyFilteredobj = { ...patientFilters };
    copyFilteredobj[`pageNumber`] = pageNumber + 1;
    copyFilteredobj[`sortField`] = persistFilteredPayload?.sortField;
    copyFilteredobj[`sortOrder`] = persistFilteredPayload?.sortOrder;
    copyFilteredobj[`pageSize`] = pageSize;
    setPageNumber(pageNumber + 1);
    setPageSize(30);
    setPersistFilteredPayload(copyFilteredobj);
    dispatchRedux(setQueryParamsFilters({ nextQueryParams: copyFilteredobj, scrollingParams: true }));
  };

  const patientsFilter = () => {
    if (assignedAs?.length) {
      timeLogAddOnSwitchPatientAndSorting();
      dispatch(emptyPatientData());
      dispatch(setSelectedPatientReport(``));
      dispatch(setAllMessages([]));
      dispatch({ type: `ISSENT`, payLoad: false });
      setSelectId(``);

      printInBrowser({ key: `asasdasdasfsd`, value: { persistFilteredPayload, inactive, autoReactivation, recurringReactivation } });
      let copyFilteredobj = { ...persistFilteredPayload };

      const searchFiltersCopy = { ...persistFilteredPayload.searchFilters };
      let monitoringTimeArray = [];
      let tierArray = [];
      let isAdherentArray = [];
      let isOnlineArray = [];
      let statusArray = [];
      let enrollmentDateArray = []
      if (notMonitored || zeroToTen || tenToTwenty || greaterThanTwenty || sentToMd) {
        if (notMonitored) {
          monitoringTimeArray.push([0]);
        }
        if (zeroToTen) {
          monitoringTimeArray.push([0, 10]);
        }
        if (tenToTwenty) {
          monitoringTimeArray.push([10, 20]);
        }
        if (greaterThanTwenty) {
          monitoringTimeArray.push([20]);
        }
        if (sentToMd) {
          monitoringTimeArray.push([`sentToMd`]);
        }
      }
      if (veryHigh || high || moderate || low) {
        if (veryHigh) {
          tierArray.push(`very high risk`);
        }
        if (high) {
          tierArray.push(`high risk`);
        }
        if (moderate) {
          tierArray.push(`moderate risk`);
        }
        if (low) {
          tierArray.push(`low risk`);
        }
      }
      if (adherent || nonAdherent || noDataFound) {
        if (adherent) {
          isAdherentArray.push(true);
        }
        if (nonAdherent) {
          isAdherentArray.push(false);
        }
        if (noDataFound) {
          isAdherentArray.push(null);
        }
      }
      if (online || offline) {
        if (online) {
          isOnlineArray.push(true);
        }
        if (offline) {
          isOnlineArray.push(false);
        }
      }
      if (active || inactive) {
        if (active) {
          statusArray.push(`active`);
        }
        if (inactive) {
          statusArray.push(`inactive`);
        }
      }
      if (autoReactivation) {
        statusArray.push(`autoReactivation`);
      }
      if (recurringReactivation) {
        statusArray.push(`recurringReactivation`);
      }

      if (last5Days) enrollmentDateArray.push(`last5Days`);

      searchFiltersCopy.monitoringTime = monitoringTimeArray;
      searchFiltersCopy.tier = tierArray;
      searchFiltersCopy.isAdherent = isAdherentArray;
      searchFiltersCopy.online = isOnlineArray;
      searchFiltersCopy[`assignedAs`] = assignedAs;
      searchFiltersCopy.status = statusArray;
      searchFiltersCopy.enrollmentDate = enrollmentDateArray
      copyFilteredobj[`searchFilters`] = searchFiltersCopy;
      copyFilteredobj[`pageNumber`] = 1;
      copyFilteredobj[`pageSize`] = 30;

      dispatch(setPatientsFilters(copyFilteredobj));
      setPersistFilteredPayload(copyFilteredobj);
      setPageNumber(1);
      dispatchRedux(setQueryParamsFilters({ nextQueryParams: copyFilteredobj, scrollingParams: false }));

      setHasMore(true);
      setOpened(false);
    } else errorToast(`Please select filter for user selection either primary or secondary.`);
  };

  const recurringValueSetter = (num, setWhole) => {
    setPersistFilteredPayload((p) => {
      const { searchFilters } = p;
      const { recurringMonth } = searchFilters;
      let hasBefore = num && recurringMonth.find((val) => val === num);
      if (hasBefore) {
        return {
          ...p,
          searchFilters: { ...searchFilters, recurringMonth: [...recurringMonth.filter((val) => val !== num)] },
        };
      } else if (setWhole) {
        return {
          ...p,
          searchFilters: { ...searchFilters, recurringMonth: setWhole },
        };
      } else {
        return {
          ...p,
          searchFilters: { ...searchFilters, recurringMonth: [...recurringMonth, num] },
        };
      }
    });
  };

  const primaryAndSecondaryProviderValueSetter = (value) => {
    setPersistFilteredPayload((p) => {
      const { searchFilters } = p;
      const { assignedAs } = searchFilters;
      let hasBefore = value && assignedAs.find((val) => val === value);
      if (hasBefore) {
        return {
          ...p,
          searchFilters: { ...searchFilters, assignedAs: [...assignedAs.filter((val) => val !== value)] },
        };
      } else {
        return {
          ...p,
          searchFilters: { ...searchFilters, assignedAs: [...assignedAs, value] },
        };
      }
    });
  };

  const recurringReactivationChecked = (value) => {
    let hasValue = recurringMonth?.find((selectedValue) => value === selectedValue);
    return hasValue ? true : false;
  };

  const primaryAndSecondaryProviderChecked = (value) => {
    let hasValue = assignedAs?.find((selectedValue) => value === selectedValue);
    return hasValue ? true : false;
  };

  let removeCommonPatients = [...new Set(patientsList?.data?.patients.map((e) => JSON.stringify(e)))].map((e) => JSON.parse(e));
  patients =
    patientsList?.data?.patients?.length &&
    removeCommonPatients?.map((patientMappingData, index) => {
      return (
        <tr
          ref={patientsListRef}
          style={{
            pointerEvents: isUserOnAudCall || isUserOnVidCall ? `none` : `auto`,
            color:
              patientMappingData.status?.current === `active`
                ? `#000`
                : patientMappingData?.status?.current === `inactive`
                  ? `rgb(193, 193, 193)`
                  : null,
          }}
          key={index}
          className={selectId === patientMappingData._id ? css.touchableSelected : css.touchable}
          onClick={
            patientData?.data?._id !== patientMappingData?._id && patientData?.loading !== true ? () => checkTimeToggle(patientMappingData) : null
          }
        >
          <th style={{ maxWidth: `50px`, textAlign: `center` }} scope="row">
            {patientMappingData.tier === `VERY HIGH RISK` ? (
              <i className="fa fa-circle" style={{ color: `rgb(235, 47, 6)` }} aria-hidden="true" />
            ) : patientMappingData.tier === `HIGH RISK` ? (
              <i className="fa fa-circle" style={{ color: `rgb(167, 9, 9)` }} aria-hidden="true" />
            ) : patientMappingData.tier === `MODERATE RISK` ? (
              <i className="fa fa-circle" style={{ color: `rgb(34, 112, 147)` }} aria-hidden="true" />
            ) : patientMappingData.tier === `LOW RISK` ? (
              <i className="fa fa-circle" style={{ color: `rgb(23, 162, 184)` }} aria-hidden="true" />
            ) : (
              <i className="fa fa-circle" aria-hidden="true" style={{ color: `#95afc0` }} />
            )}
          </th>

          <td style={{ maxWidth: `30px`, textAlign: `center` }} scope="row">
            {patientMappingData?.lastMonitoredAt ? `${momentWrapper().diff(patientMappingData?.lastMonitoredAt, `days`)} d` : `-`}
          </td>
          <td style={{ maxWidth: `20px` }} scope="row">
            {patientMappingData?.interactiveCommunication ? (
              <MissedVideoCallIcon
                style={{
                  color: `#1699c5`,
                  width: `20px`,
                }}
              />
            ) : patientMappingData?.leftVoiceMail ? (
              <VoicemailIcon
                style={{
                  color: `#1699c5`,
                  width: `20px`,
                }}
              />
            ) : null}
          </td>

          <td style={{ maxWidth: `72px` }} className={css.truncate}>
            {capitalizeFirstLetter(patientMappingData.lastName)}
          </td>
          <td style={{ maxWidth: `72px` }} className={css.truncate}>
            {capitalizeFirstLetter(patientMappingData.firstName)}
          </td>
          {!isDme() && (
            <td style={{ maxWidth: `60px`, textAlign: `center` }}>
              {`${Math.floor(patientMappingData?.timeMonitored / 60) < 10
                ? `0` + Math.floor(patientMappingData?.timeMonitored / 60)
                : Math.floor(patientMappingData?.timeMonitored / 60)
                }:${Math.floor(patientMappingData?.timeMonitored) - 60 * Math.floor(patientMappingData?.timeMonitored / 60) < 10
                  ? `0` + (Math.floor(patientMappingData?.timeMonitored) - 60 * Math.floor(patientMappingData?.timeMonitored / 60))
                  : Math.floor(patientMappingData?.timeMonitored) - 60 * Math.floor(patientMappingData?.timeMonitored / 60)
                }`}
            </td>
          )}
        </tr>
      );
    });

  patientsBillerRole =
    patientsList?.data?.patients?.length &&
    removeCommonPatients?.map((patientMappingDataForBillerRole, index) => {
      return (
        <tr
          key={index}
          className={selectId === patientMappingDataForBillerRole._id ? css.touchableSelected : css.touchable}
          onClick={
            patientData?.data?._id !== patientMappingDataForBillerRole?._id && patientData?.loading !== true
              ? () => checkTimeToggle(patientMappingDataForBillerRole)
              : null
          }
        >
          <td></td>
          <td style={{ maxWidth: `72px` }} className={css.truncate}>
            {capitalizeFirstLetter(patientMappingDataForBillerRole.lastName)}
          </td>
          <td style={{ maxWidth: `72px` }} className={css.truncate}>
            {capitalizeFirstLetter(patientMappingDataForBillerRole.firstName)}
          </td>
          <td style={{ maxWidth: `100px`, textAlign: `center` }}>
            {momentWrapper(patientMappingDataForBillerRole.dateOfBirth).format(`MM/DD/YYYY`)}
          </td>
          <td style={{ maxWidth: `100px`, textAlign: `center` }}>
            {patientMappingDataForBillerRole.status?.current === "active" ?
              <BadgePill type={`badge-success`}>Active</BadgePill> :
              <BadgePill type={`badge-danger`}>Inactive</BadgePill>
            }
          </td>
        </tr>
      );
    });

  return (
    <div
      id={css.listAllPatient}
      className="table-responsive"
      style={{
        pointerEvents: videoCallStatus ? `none` : null,
        overflowY: `auto`,
        // width: user?.isBiller && !user.isSuperAdmin && !user.isProvider && !user.isClinicalStaff ? '30%' : '25%',
      }}
    >
      {user.isSuperAdmin || user.isProvider || user.isClinicalStaff || user.isBiller ? (
        <Paper
          sx={{
            padding: `6px 5px 6px 4px`,
            borderRadius: `1px`,
            // backgroundColor: 'red',
            // overflow: 'auto',
            // zIndex: 25
          }}
        >
          <button
            onClick={changeFiltersView}
            className="btn btn-light btn-sm w-100 pl-2 pr-2 "
            style={{
              border: `1px solid #c4c4c4`,
              color: `grey`,
              fontSize: `small`,
              textAlign: `start`,
            }}
            type="button"
          >
            <div className="d-flex justify-content-between align-items-center">
              <span className="text-truncate">Filter</span>
              <i className="fa fa-chevron-down" style={{ fontSize: `x-small` }} aria-hidden="true"></i>
            </div>
          </button>
          <Collapse in={opened} mountOnEnter unmountOnExit timeout={500}>
            <div
              style={{
                width: `100%`,
                // height: `56vh`,
                overflowY: `scroll`,
                padding: `3%`,
                position: `relative`,
              }}
            >
              <Box sx={{ width: `80%`, textAlign: `end` }}>
                <ToolTipProvider
                  toolTipProps={{
                    title: `Filter Patients!`,
                    arrow: true,
                    placement: `top`,
                  }}
                  element={
                    <button className={css.customFilterButton} type="button" data-toggle="collapse" onClick={() => patientsFilter()}>
                      <i className="fa fa-filter"></i>
                      Filter
                    </button>
                  }
                />
              </Box>
              <form aria-labelledby="dropdownMenuButton">
                {!user.isBiller &&
                  <>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `100ms` : `0ms` }}>
                      <p style={{ fontWeight: 600 }}>Assigned As</p>
                    </Zoom>

                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `100ms` : `0ms` }}>
                      <div>
                        <input
                          checked={primaryAndSecondaryProviderChecked(`primary`)}
                          onChange={() => {
                            primaryAndSecondaryProviderValueSetter(`primary`);
                          }}
                          type="checkbox"
                          name="Primary"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="Primary" style={{ fontSize: `x-small`, color: `black` }}>
                          Primary
                        </label>
                      </div>
                    </Zoom>

                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `100ms` : `0ms` }}>
                      <div>
                        <input
                          checked={primaryAndSecondaryProviderChecked(`secondary`)}
                          onChange={() => {
                            primaryAndSecondaryProviderValueSetter(`secondary`);
                          }}
                          type="checkbox"
                          name="Secondary"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="Secondary" style={{ fontSize: `x-small`, color: `black` }}>
                          Secondary
                        </label>
                      </div>
                    </Zoom>

                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `100ms` : `0ms` }}>
                      <p style={{ fontWeight: 600 }}>Monitoring time:</p>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `200ms` : `0ms` }}>
                      <div>
                        <input
                          checked={notMonitored}
                          onChange={(e) => {
                            setNotMonitored(e.target.checked ? `notMonitored` : ``);
                          }}
                          type="checkbox"
                          name="notMonitored"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="notMonitored" style={{ fontSize: `x-small`, color: `black` }}>
                          Not Opened
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `300ms` : `0ms` }}>
                      <div>
                        <input
                          checked={zeroToTen}
                          onChange={(e) => {
                            setZeroToTen(e.target.checked ? `zeroToTen` : ``);
                          }}
                          type="checkbox"
                          name="zeroToTen"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="zeroToTen" style={{ fontSize: `x-small`, color: `black` }}>
                          0-10 min
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `400ms` : `0ms` }}>
                      <div>
                        <input
                          checked={tenToTwenty}
                          onChange={(e) => {
                            setTenToTwenty(e.target.checked ? `tenToTwenty` : ``);
                          }}
                          type="checkbox"
                          name="tenToTwenty"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="tenToTwenty" style={{ fontSize: `x-small`, color: `black` }}>
                          10-20 min
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `500ms` : `0ms` }}>
                      <div>
                        <input
                          checked={greaterThanTwenty}
                          onChange={(e) => {
                            setGreaterThanTwenty(e.target.checked ? `greaterThanTwenty` : ``);
                          }}
                          type="checkbox"
                          name="greaterThanTwenty"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="greaterThanTwenty" style={{ fontSize: `x-small`, color: `black` }}>
                          {`>20 min`}
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `600ms` : `0ms` }}>
                      <div>
                        <input
                          checked={sentToMd}
                          onChange={(e) => {
                            setSentToMd(e.target.checked ? `sentToMd` : ``);
                          }}
                          type="checkbox"
                          name="sentToMd"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="greaterThanTwenty" style={{ fontSize: `x-small`, color: `black` }}>
                          {`Sent to MD`}
                        </label>
                      </div>
                    </Zoom>

                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `100ms` : `0ms` }}>
                      <p className="pt-2" style={{ fontWeight: 600 }}>
                        Risk Stratification:
                      </p>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `200ms` : `0ms` }}>
                      <div>
                        <input
                          checked={veryHigh}
                          onChange={(e) => {
                            setVeryHigh(e.target.checked ? `very high risk` : ``);
                          }}
                          type="checkbox"
                          name="veryHigh"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="veryHigh" style={{ fontSize: `x-small`, color: `black` }}>
                          Very High
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `300ms` : `0ms` }}>
                      <div>
                        <input
                          checked={high}
                          onChange={(e) => {
                            setHigh(e.target.checked ? `high risk` : ``);
                          }}
                          type="checkbox"
                          name="high"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="high" style={{ fontSize: `x-small`, color: `black` }}>
                          High
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `400ms` : `0ms` }}>
                      <div>
                        <input
                          checked={moderate}
                          onChange={(e) => {
                            setModerate(e.target.checked ? `moderate risk` : ``);
                          }}
                          type="checkbox"
                          name="moderate"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="moderate" style={{ fontSize: `x-small`, color: `black` }}>
                          Medium
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `500ms` : `0ms` }}>
                      <div>
                        <input
                          checked={low}
                          onChange={(e) => {
                            setLow(e.target.checked ? `low risk` : ``);
                          }}
                          type="checkbox"
                          name="low"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="low" style={{ fontSize: `x-small`, color: `black` }}>
                          Low
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `600ms` : `0ms` }}>
                      <p className="pt-2" style={{ fontWeight: 600 }}>
                        Adherence:
                      </p>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `700ms` : `0ms` }}>
                      <div>
                        <input
                          checked={adherent}
                          onChange={(e) => {
                            setAdherent(e.target.checked ? `adherent` : ``);
                          }}
                          type="checkbox"
                          name="adherent"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="adherent" style={{ fontSize: `x-small`, color: `black` }}>
                          Adherent
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `800ms` : `0ms` }}>
                      <div>
                        <input
                          checked={nonAdherent}
                          onChange={(e) => {
                            setNonAdherent(e.target.checked ? `nonAdherent` : ``);
                          }}
                          type="checkbox"
                          name="nonAdherent"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="nonAdherent" style={{ fontSize: `x-small`, color: `black` }}>
                          Non Adherent
                        </label>
                      </div>
                    </Zoom>

                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `900ms` : `0ms` }}>
                      <div>
                        <input
                          checked={noDataFound}
                          onChange={(e) => {
                            setNoDataFound(e.target.checked ? `NO_DATA` : ``);
                          }}
                          type="checkbox"
                          name="noDataFound"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="noDataFound" style={{ fontSize: `x-small`, color: `black` }}>
                          {`No data > 30 days`}
                        </label>
                      </div>
                    </Zoom>
                    {/* ------------------ */}
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `300ms` : `0ms` }}>
                      <p className="pt-2" style={{ fontWeight: 600 }}>
                        Online/Offline:
                      </p>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `400ms` : `0ms` }}>
                      <div>
                        <input
                          checked={online}
                          onChange={(e) => {
                            setOnline(e.target.checked ? `online` : ``);
                          }}
                          type="checkbox"
                          name="online"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="online" style={{ fontSize: `x-small`, color: `black` }}>
                          Online
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `500ms` : `0ms` }}>
                      <div>
                        <input
                          checked={offline}
                          onChange={(e) => {
                            setOffline(e.target.checked ? `offline` : ``);
                          }}
                          type="checkbox"
                          name="offline"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="offline" style={{ fontSize: `x-small`, color: `black` }}>
                          Offline
                        </label>
                      </div>
                    </Zoom>
                  </>
                }
                {/* ---------Active | Inactive-------- */}
                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `300ms` : `0ms` }}>
                  <p className="pt-2" style={{ fontWeight: 600 }}>
                    Status:
                  </p>
                </Zoom>
                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `400ms` : `0ms` }}>
                  <div>
                    <input
                      checked={active}
                      onChange={(e) => {
                        setActive(e.target.checked ? `active` : ``);
                      }}
                      type="checkbox"
                      name="active"
                      style={{ width: `11px`, height: `11px` }}
                    />
                    <label htmlFor="active" style={{ fontSize: `x-small`, color: `black` }}>
                      Active
                    </label>
                  </div>
                </Zoom>
                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `500ms` : `0ms` }}>
                  <div>
                    <input
                      checked={inactive}
                      onChange={(e) => {
                        setInactive(e.target.checked ? `inactive` : ``);
                      }}
                      type="checkbox"
                      name="inactive"
                      style={{ width: `11px`, height: `11px` }}
                    />
                    <label htmlFor="inactive" style={{ fontSize: `x-small`, color: `black` }}>
                      Inactive
                    </label>
                  </div>
                </Zoom>
                {!user.isBiller &&
                  <>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `500ms` : `0ms` }}>
                      <div>
                        <input
                          checked={autoReactivation}
                          onChange={(e) => {
                            setAutoReactivation(e.target.checked ? `autoReactivation` : ``);
                          }}
                          type="checkbox"
                          name="autoReactivation"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="autoReactivation" style={{ fontSize: `x-small`, color: `black` }}>
                          Auto Reactivation
                        </label>
                      </div>
                    </Zoom>

                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `500ms` : `0ms` }}>
                      <div>
                        <input
                          checked={recurringReactivation}
                          onChange={(e) => {
                            setRecurringReactivation(e.target.checked ? `recurringReactivation` : ``);
                          }}
                          type="checkbox"
                          name="recurringReactivation"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="recurringReactivation" style={{ fontSize: `x-small`, color: `black` }}>
                          Recurring Reactivation
                        </label>
                      </div>
                    </Zoom>

                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `100ms` : `0ms` }}>
                      <div>
                        <input
                          checked={recurringReactivationChecked(2)}
                          onChange={() => {
                            recurringValueSetter(2);
                          }}
                          type="checkbox"
                          name="inactive"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="inactive" style={{ fontSize: `x-small`, color: `black` }}>
                          2 Months
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `200ms` : `0ms` }}>
                      <div>
                        <input
                          checked={recurringReactivationChecked(3)}
                          onChange={() => {
                            recurringValueSetter(3);
                          }}
                          type="checkbox"
                          name="inactive"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="inactive" style={{ fontSize: `x-small`, color: `black` }}>
                          3 Months
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `300ms` : `0ms` }}>
                      <div>
                        <input
                          checked={recurringReactivationChecked(4)}
                          onChange={() => {
                            recurringValueSetter(4);
                          }}
                          type="checkbox"
                          name="inactive"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="inactive" style={{ fontSize: `x-small`, color: `black` }}>
                          4 Months
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `400ms` : `0ms` }}>
                      <div>
                        <input
                          checked={recurringReactivationChecked(6)}
                          onChange={() => {
                            recurringValueSetter(6);
                          }}
                          type="checkbox"
                          name="inactive"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="inactive" style={{ fontSize: `x-small`, color: `black` }}>
                          6 Months
                        </label>
                      </div>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `500ms` : `0ms` }}>
                      <p className="pt-2" style={{ fontWeight: 600 }}>
                        Date Of Enrollment:
                      </p>
                    </Zoom>
                    <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `600ms` : `0ms` }}>
                      <div>
                        <input
                          checked={last5Days}
                          onChange={() => setLast5Days((prev) => !prev)}
                          type="checkbox"
                          name="last5Days"
                          style={{ width: `11px`, height: `11px` }}
                        />
                        <label htmlFor="last5Days" style={{ fontSize: `x-small`, color: `black` }}>
                          Within last 5 days
                        </label>
                      </div>
                    </Zoom>
                  </>
                }
              </form>
            </div>
          </Collapse>
        </Paper>
      ) : null}

      <div id="scrollableDiv" style={{ height: `96%`, overflowY: `scroll` }}>
        <InfiniteScroll
          scrollableTarget="scrollableDiv"
          dataLength={patientsList?.data?.patients ? patientsList?.data?.patients?.length : null}
          next={() => fetchMoreData()}
          hasMore={hasMore}
        >
          {user?.isBiller && !user.isSystemAdministrator && !user.isSuperAdmin && !user.isProvider && !user.isClinicalStaff ? (
            <table className="table table-striped table-sm" id="patientTable">
              <thead style={{ backgroundColor: `white`, color: `black` }}>
                <tr>
                  <th></th>
                  <th className="py-2" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`lastName`)}>
                    LAST
                    {sortKey === `lastName` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `lastName` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                  <th className="py-2" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`firstName`)}>
                    FIRST
                    {sortKey === `firstName` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `firstName` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                  <th className="py-2 text-center" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`dateOfBirth`)}>
                    Date Of Birth
                    {sortKey === `dateOfBirth` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `dateOfBirth` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                  <th className="py-2 text-center" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`status`)}>
                    Status
                    {sortKey === `status` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `status` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody
                style={{
                  pointerEvents: isAllowToClickOnPatient ? `none` : `unset`,
                }}
              >
                {patientsList?.data?.patients?.length ? (
                  <>
                    {patientsBillerRole}
                    <tr>
                      <td colSpan="6" className="text-center" style={{ color: `#000` }}>
                        <div>
                          {patientsList?.data?.patients?.length === patientsList?.data?.pager?.totalRecords ? <b>End of patients list</b> : null}
                        </div>
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center" style={{ color: `#c1c1c1` }}>
                      No Patient Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <table style={{ width: `130% !important` }} className="table table-striped table-sm" id="patientTable">
              <thead style={{ backgroundColor: `white`, color: `black` }}>
                <tr>
                  <th className="py-2 text-center" scope="col">
                    CAT
                  </th>
                  <th className="py-2 text-center" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`lastMonitoredAt`)}>
                    SINCE
                    {sortKey === `lastMonitoredAt` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `lastMonitoredAt` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                  <th className="py-2" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`interactiveCommunication`)}>
                    COM
                    {sortKey === `interactiveCommunication` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `interactiveCommunication` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                  <th className="py-2" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`lastName`)}>
                    LAST
                    {sortKey === `lastName` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `lastName` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                  <th className="py-2" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`firstName`)}>
                    FIRST
                    {sortKey === `firstName` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `firstName` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                  {!isDme() && (
                    <th className="py-2 text-center" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`timeMonitored`)}>
                      TIME
                      {sortKey === `totalTime` && sortOrder === 1 ? (
                        <i className="fa fa-sort-asc ml-2" />
                      ) : sortKey === `totalTime` && sortOrder === -1 ? (
                        <i className="fa fa-sort-desc ml-2" />
                      ) : (
                        <i className="fa fa-sort ml-2" />
                      )}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody
                style={{
                  pointerEvents: isAllowToClickOnPatient ? `none` : `unset`,
                }}
              // id={audioCallStatus && css.disabledIfCallTrue}
              >
                {patientsList?.data?.patients?.length ? (
                  <>
                    {patients}
                    <tr>
                      <td colSpan="6" className="text-center" style={{ color: `#000` }}>
                        <div>
                          {patientsList?.data?.patients?.length === patientsList?.data?.pager?.totalRecords ? <b>End of patients list</b> : null}
                        </div>
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center" style={{ color: `#c1c1c1` }}>
                      No Patient Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </InfiniteScroll>
      </div>
      {patientsList?.loading && <PuffSpinner />}
    </div>
  );
};
