import {
  HANDLE_TIMER,
  LIST_ALL_PATIENTS_LOADING,
  LIST_ALL_PATIENTS,
  INITIALIZE_START_END_TIME,
  SELECTED_PATIENT,
  SELECTED_PATIENT_LOADING,
  SELECTED_PATIENT_ERROR,
  SELECTED_PATIENT_REPORT,
  PATIENTS_FILTERS,
  PATIENT_DATA_EMPTY,
  LIST_ALL_PATIENTS_EMPTY,
  LIST_ALL_PATIENTS_ERROR,
  SET_VIDEO_CALL_STATUS,
  HANDLE_SELECTED_TAB,
  REPORT_LOADING,
  HIDE_TIMER,
  SET_MESSAGES,
  SET_MESSAGES_CURRENT_PAGE,
  SET_MESSAGES_HASMORE,
  SMSRESPONSE,
  TXT_ERROR,
  FIRST_RESPOSNE,
  SET_NOTES,
  SELECTED_PATIENT_UNUSE,
  SOCKET_RESPONSE,
  SAVE_PAGE_REFRESH_PATIENT_INFO,
  COMMUNICATION_PATIENT,
  ALL_TIME_LOGS,
  ALL_TIME_LOGS_LOADING,
  CHANGE_LEFT_VOICE_MAIL_STATUS,
  PATIENT_SWITCHING,
  CHANGE_TIME_MONITORED,
  PATIENT_DATA_FLAG,
  SAVE_CSV_BULK_IMPORT_PATIENT,
  EMPTY_CSV_BULK_IMPORT_PATIENT,
  ACTIVE_CONNECTION,
  PATIENT_API_CAN_TOK_LIST,
  PATIENT_API_CAN_TOK_DETAIL,
} from './patientTypes';
import { store } from 'src/redux/store';
import { momentWrapper } from 'src/momentWrapper';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import {
  LIST_ALL_PATIENTS_URL,
  PATIENT_ALL_TEXT_MESSAGES_URL,
  REPORT_NOTES,
  getRequest,
  GET_PATIENT_BY_ID_URL,
  postRequest,
  ADD_PATIENT_MONITORING_TIME_LOG_URL,
  LIST_ALL_PATIENTS_BILLER_ROLE_URL,
  GETTING_REPORT_STATUS,
  PATIENT_TIME_LOGS_URL,
} from 'src/crud/crud';
import { isDme, printInBrowser } from 'src/utils';
import axios from 'axios'
let updatePatientlogFlag = false;

export const handleTimer = (payload) => {
  return {
    type: HANDLE_TIMER,
    payload: payload,
    oldPatientState: store.getState()?.patient,
  };
};
export const initializeStartAndEndTime = (payload) => {
  return {
    type: INITIALIZE_START_END_TIME,
    payload: payload,
  };
};
export const emptyPatientsList = (payload) => {
  return {
    type: LIST_ALL_PATIENTS_EMPTY,
    payload: payload,
  };
};
export const patientsListLoading = (payload) => {
  return {
    type: LIST_ALL_PATIENTS_LOADING,
    payload: payload,
  };
};

export const saveBulkImportCsv = (payLoad) => {
  return {
    type: SAVE_CSV_BULK_IMPORT_PATIENT,
    payLoad,
  };
};
export const emptyBulkIMportCsv = () => {
  return {
    type: EMPTY_CSV_BULK_IMPORT_PATIENT,
  };
};

export const timeLogsLoading = (payload) => {
  return {
    type: ALL_TIME_LOGS_LOADING,
    payload: payload,
  };
};
export const patientsListError = (payload) => {
  return {
    type: LIST_ALL_PATIENTS_ERROR,
    payload: payload,
  };
};
export const selectedPatientLoading = (payload) => {
  return {
    type: SELECTED_PATIENT_LOADING,
    payload: payload,
  };
};
export const selectedPatientError = (payload) => {
  return {
    type: SELECTED_PATIENT_ERROR,
    payload: payload,
  };
};
export const emptyPatientData = (payload) => {
  return {
    type: PATIENT_DATA_EMPTY,
    payload: payload,
  };
};

export const updateAllTimeLogsAfterAudioCall = (payload) => {
  const patientId = store.getState()?.patient?.patientData.data._id;
  localStorage.setItem(`updatePatientlogFlag`, true);
  return (dispatch) => {
    let reportId;
    postRequest(PATIENT_TIME_LOGS_URL + `/?reportId=${reportId}`, {
      reportId: undefined,
      startDate: momentWrapper().startOf(`month`).toISOString(),
      endDate: payload.endDate,
      patientId: patientId,
      updateLag: true,
    })
      .then((res) => {
        dispatch({
          type: ALL_TIME_LOGS,
          payload: res.data,
        });
        updatePatientlogFlag = true;
      })
      .catch(() => { });
  };
};
export const getAllTimeLogs = (payload) => {
  const { startDate, endDate, patientId, reportId } = payload;
  return (dispatch) => {
    dispatch(timeLogsLoading({ type: ALL_TIME_LOGS_LOADING, payload: `` }));
    postRequest(PATIENT_TIME_LOGS_URL + `/?reportId=${reportId}`, {
      startDate: startDate || momentWrapper().startOf(`month`).toISOString(),
      endDate: endDate || momentWrapper().toISOString(),
      patientId,
    })
      .then((res) => {
        dispatch({
          type: ALL_TIME_LOGS,
          payload: res.data,
        });
      })
      .catch(() => { });
    // } else {
    //   localStorage.removeItem(`updatePatientlogFlag`);
    // }
  };
};

export const switchPatient = (payLoad) => {
  return {
    type: PATIENT_SWITCHING,
    payLoad: payLoad,
  };
};
export const changeAccumulatedTime = (payLoad) => {
  return {
    type: CHANGE_TIME_MONITORED,
    payLoad: payLoad,
  };
};

export const saveTimerUser = (payload) => {
  return (dispatch) => {
    const {
      videoOrAudioOn,
      callSuccess = false,
      statuss,
      sources,
      callSelectedPatient,
      callSelectedPatientData,
      errorCatcher = () => null,
    } = payload;
    // Add loader on Patient Data when we save time in database
    dispatch(selectedPatientLoading({ type: SELECTED_PATIENT_LOADING, payload: `` }));
    // Add loader on Patient List when we save time in database
    dispatch(patientsListLoading({ type: LIST_ALL_PATIENTS_LOADING, payload: `` }));
    // ADD_PATIENT_MONITORING_TIME_LOG_URL + `${patientId}` + `?reportId=${reportId}`
    let patientId = store.getState()?.patient?.patientData?.data?._id;
    postRequest(
      ADD_PATIENT_MONITORING_TIME_LOG_URL + `${patientId}` + `?reportId=${null}`,
      {
        startDate: store.getState()?.patient?.timer?.startTime, // User start time
        endDate: store.getState()?.patient?.timer?.endTime, // User end time
        startOfMonth: momentWrapper().startOf(`month`).toISOString(),
        now: momentWrapper().toISOString(),
        source: sources || store.getState()?.patient?.timer?.viewSource, // Source  i.e View Report, Audio Call, Video Call
        type: `Auto`, // Type: i.e Auto,Manual
        description: statuss || store.getState()?.patient?.timer?.logDescription, // Statuss Description
        callSuccess: callSuccess,
      }
      //patient id in params
    )
      .then((res) => {
        // This list is used to update Patients List
        let updatedPatientsList = [];

        // This list is used to update Patients
        let updatePatientData = {};

        // If a user make a video or audio call then we update its Video or audio Call status
        if (callSuccess) {
          // Updating patient Data
          updatePatientData = {
            ...store.getState()?.patient?.patientData?.data,
            lastMonitoredAt: store.getState()?.patient?.timer?.endTime,
            timeMonitored: res?.data?.timeLog?.accumulatedTime,
            interactiveCommunication: true,
          };

          // Updating patients List Data
          updatedPatientsList = store.getState()?.patient?.patientsList?.data?.patients?.map((patient) => {
            return patient?._id === store.getState()?.patient?.patientData?.data?._id
              ? {
                ...patient,
                lastMonitoredAt: store.getState()?.patient?.timer?.endTime,
                timeMonitored: res?.data?.timeLog?.accumulatedTime,
                interactiveCommunication: true,
              }
              : patient;
          });
        }

        // // If there is no audio or video call normal flow
        else {
          updatePatientData = {
            ...store.getState()?.patient?.patientData?.data,
            lastMonitoredAt: store.getState()?.patient?.timer?.endTime,
            timeMonitored: res?.data?.timeLog?.accumulatedTime,
          };

          // updating patients lists info
          updatedPatientsList = store.getState()?.patient?.patientsList?.data?.patients?.map((patient) => {
            return patient?._id === res?.data?.timeLog?._patientId //patient id
              ? {
                ...patient,
                lastMonitoredAt: store.getState()?.patient?.timer?.endTime,
                timeMonitored: res?.data?.timeLog?.accumulatedTime,
              }
              : patient;
          });
        }

        // updating patient Data on Redux SELECTED_PATIENT (Patient list)
        dispatch({
          type: SELECTED_PATIENT,
          payload: updatePatientData,
        });
        // updating patients lists on redux LIST_ALL_PATIENTS (all patients list)
        dispatch({
          type: LIST_ALL_PATIENTS,
          payload: {
            error: false,
            loading: false,
            success: true,
            pager: store.getState()?.patient?.patientsList?.data?.pager,
            patients: updatedPatientsList,
          },
        });
        if (callSelectedPatient) {
          dispatch(handleSelectedPatient({ patient: callSelectedPatientData }));
        }

        // // initialize start and end time to zero
        dispatch(initializeStartAndEndTime());

        // // if we have to turn on the video timer
        if (videoOrAudioOn === `video`) {
          dispatch(handleTimer({ status: true, timeEnd: null, videoOrAudio: `video` }));
        }

        // // if we have to turn on the timer auto after complete audio or video
        if (videoOrAudioOn === `timerOn`) {
          dispatch(handleTimer({ status: true, timeEnd: null, videoOrAudio: null, autoTimer: `false` }));
        }

        // // Success Message on Timer Save in Database
        successToast(`Time Log Added.`);
        // return res;
      })
      .catch((err) => {
        if (errorCatcher) errorCatcher();
        dispatch(
          selectedPatientError({
            type: SELECTED_PATIENT_ERROR,
            payload: err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error,
          })
        );
        if (err?.response?.status === 401) {
          errorToast(`Auth failed`);
        }
        if (err?.response?.status !== 401) {
          errorToast(`An error occurred.`);
        }
        dispatch(patientsListError());
        return null;
      });
  };
};
// updating time logs
export const updateTimeLog = (payload) => {
  return (dispatch) => {
    const patientData = store.getState()?.patient?.patientData;
    const patientsList = store.getState()?.patient?.patientsList;

    if (payload?.callSuccess) {
      // Patient Data update
      let copyInsideDataObject = patientData?.data;
      copyInsideDataObject[`timeMonitored`] = payload?.accumulatedTime;
      copyInsideDataObject[`interactiveCommunication`] = true;
      // updating patient Data
      dispatch({
        type: SELECTED_PATIENT,
        payload: copyInsideDataObject,
      });

      // Patient List

      let copyPatientsList = patientsList;
      let copyPatientsListDataObject = patientsList?.data;
      let patientsArray = copyPatientsList?.data?.patients;
      for (let i = 0; i < patientsArray?.length; i++) {
        if (payload?._patientId === patientsArray[i]?._id) {
          patientsArray[i].timeMonitored = payload?.accumulatedTime;
          patientsArray[i].interactiveCommunication = true;
        }
      }
      copyPatientsListDataObject[`patients`] = patientsArray;
      copyPatientsList[`data`] = copyPatientsListDataObject;
      // Dispatch here
      dispatch({
        type: LIST_ALL_PATIENTS,
        payload: copyPatientsListDataObject,
      });
    } else {
      // Patient Data update
      let copyInsideDataObject = patientData?.data;
      copyInsideDataObject[`timeMonitored`] = payload?.accumulatedTime;
      // updating patient Data
      dispatch({
        type: SELECTED_PATIENT,
        payload: copyInsideDataObject,
      });

      // Patient List

      let copyPatientsList = patientsList;
      let copyPatientsListDataObject = patientsList?.data;
      let patientsArray = copyPatientsList?.data?.patients;
      for (let i = 0; i < patientsArray?.length; i++) {
        if (payload?._patientId === patientsArray[i]?._id) {
          patientsArray[i].timeMonitored = payload?.accumulatedTime;
        }
      }
      copyPatientsListDataObject[`patients`] = patientsArray;
      copyPatientsList[`data`] = copyPatientsListDataObject;
      // Dispatch here
      dispatch({
        type: LIST_ALL_PATIENTS,
        payload: copyPatientsListDataObject,
      });
    }
  };
};
export const deletePatientTimeLog = (payload) => {
  return (dispatch) => {
    const patientsList = store.getState()?.patient?.patientsList;
    const patientData = store.getState()?.patient?.patientData;
    // Patient Data delete timelog
    let copyInsideDataObject = patientData?.data;

    copyInsideDataObject[`timeMonitored`] = copyInsideDataObject?.timeMonitored - payload?.duration;

    // updating patient Data
    dispatch({
      type: SELECTED_PATIENT,
      payload: copyInsideDataObject,
    });

    // Patient List delete time log

    let copyPatientsList = patientsList;
    let copyPatientsListDataObject = patientsList?.data;
    let patientsArray = copyPatientsList?.data?.patients;
    for (let i = 0; i < patientsArray?.length; i++) {
      if (payload?._patientId === patientsArray[i]?._id) {
        patientsArray[i].timeMonitored = patientsArray[i].timeMonitored - payload?.duration;
      }
    }
    copyPatientsListDataObject[`patients`] = patientsArray;
    copyPatientsList[`data`] = copyPatientsListDataObject;
    // Dispatch here
    dispatch({
      type: LIST_ALL_PATIENTS,
      payload: copyPatientsListDataObject,
    });
  };
};
export const updatePatientMask = (payload) => {
  return (dispatch) => {
    const patientData = store.getState()?.patient?.patientData;
    let copyInsideDataObject = patientData?.data;
    let copyInsideMaskObject = patientData?.data?.mask;
    if (patientData?.data?.mask) {
      copyInsideMaskObject[`type`] = payload?.mask?.type;
      copyInsideMaskObject[`size`] = payload?.mask?.size;
      copyInsideMaskObject[`manufacturer`] = payload?.mask?.manufacturer;
      copyInsideDataObject[`mask`] = copyInsideMaskObject;
    } else {
      copyInsideDataObject[`mask`] = payload?.mask;
    }
    // updating patient Data
    dispatch({
      type: SELECTED_PATIENT,
      payload: copyInsideDataObject,
    });
  };
};
// update Edit Patient
export const updateEditPatient = (payload) => {
  return (dispatch) => {
    const patientData = store.getState()?.patient?.patientData;
    let copyInsideDataObject = patientData?.data;
    if (payload?.deactivatedAt) {
      copyInsideDataObject[`deactivatedAt`] = payload?.deactivatedAt;
    } else {
      copyInsideDataObject[`deactivatedAt`] = ``;
    }
    if (payload?.baseLineAHI) {
      copyInsideDataObject[`baseLineAHI`] = payload?.baseLineAHI;
    } else {
      copyInsideDataObject[`baseLineAHI`] = ``;
    }

    if (payload?.phoneNumber === null) {
      copyInsideDataObject[`phoneNumber`] = `(000) 000-0000`;
    } else {
      copyInsideDataObject[`phoneNumber`] = payload?.phoneNumber;
    }
    copyInsideDataObject[`MRN`] = payload?.MRN;
    copyInsideDataObject[`gender`] = payload?.gender;
    copyInsideDataObject[`email`] = payload?.email;
    copyInsideDataObject[`insuranceCompany`] = payload?.insuranceCompany;
    copyInsideDataObject[`WIP`] = payload?.WIP;
    copyInsideDataObject[`brightreeID`] = payload?.brightreeID;
    copyInsideDataObject[`salesOrderID`] = payload?.salesOrderID;
    copyInsideDataObject[`followUpDate`] = payload?.followUpDate;
    copyInsideDataObject[`MFL`] = payload?.MFL;
    copyInsideDataObject[`daysInWIP`] = payload?.daysInWIP;
    copyInsideDataObject[`primaryDiagnosis`] = payload?.primaryDiagnosis;
    copyInsideDataObject[`secondaryDiagnosis`] = payload?.secondaryDiagnosis;
    let copyInsideMaskObject = copyInsideDataObject.mask;
    if (payload?.mask) {
      copyInsideMaskObject[`type`] = payload?.mask?.type;
      copyInsideMaskObject[`size`] = payload?.mask?.size;
      copyInsideMaskObject[`manufacturer`] = payload?.mask?.manufacturer;
      copyInsideDataObject[`mask`] = copyInsideMaskObject;
    }

    if (payload?.reactivateAt || payload?.reactivateAt === null) {
      copyInsideDataObject = { ...copyInsideDataObject, reactivateAt: payload?.reactivateAt };
    }

    copyInsideDataObject[`status`] = payload?.status;

    if (payload?.emrPatientInternalId) {
      copyInsideDataObject[`emrPatientInternalId`] = payload?.emrPatientInternalId;
    } else {
      copyInsideDataObject[`emrPatientInternalId`] = ``;
    }
    if (payload?.emrPatientExternalId) {
      copyInsideDataObject[`emrPatientExternalId`] = payload?.emrPatientExternalId;
    } else {
      copyInsideDataObject[`emrPatientExternalId`] = ``;
    }

    copyInsideDataObject[`notes`] = payload?.notes;
    // updating patient Data
    dispatch({
      type: SELECTED_PATIENT,
      payload: copyInsideDataObject,
    });
  };
};

export const setQueryParamsFilters = (payload) => {
  return (dispatch) => {
    dispatch(setAllPatients(payload));
  };
};

export const saveOnPageRefreshPatientInfo = (payLoad) => {
  return {
    type: SAVE_PAGE_REFRESH_PATIENT_INFO,
    payLoad: payLoad,
  };
};

export const setAllPatients = (payload) => {
  return (dispatch) => {
    const { nextQueryParams, scrollingParams } = payload;
    const user = store.getState()?.user?.user;
    const patientsList = store.getState()?.patient?.patientsList;

    // Add loader on Patient List when we save time in database
    dispatch(patientsListLoading({ type: LIST_ALL_PATIENTS_LOADING, payload: `` }));

    if (user?.isBiller && !user.isSystemAdministrator && !user.isSuperAdmin && !user.isProvider && !user.isClinicalStaff) {
      let copyNextQueryParams = { ...nextQueryParams };
      const { searchFilters: { status }, ...paramObj } = copyNextQueryParams;
      paramObj[`startDate`] = momentWrapper().startOf(`month`).toISOString();
      paramObj[`endDate`] = momentWrapper().toISOString();

      const updatedParamObj = { ...paramObj, searchFilters: { status } };

      postRequest(LIST_ALL_PATIENTS_BILLER_ROLE_URL, updatedParamObj)
        .then((res) => {
          if (scrollingParams) {
            let previousData = patientsList?.data?.patients;
            let latestData = res?.data?.data;
            let concatenatedPatients = previousData.concat(latestData);
            const copyPatientResponseObj = {};
            copyPatientResponseObj[`patients`] = concatenatedPatients;
            copyPatientResponseObj[`pager`] = res?.data?.pager;
            dispatch({
              type: LIST_ALL_PATIENTS,
              payload: copyPatientResponseObj,
            });
          } else {
            const data = {};
            data[`patients`] = res?.data?.data;
            data[`pager`] = res?.data?.pager;
            dispatch({
              type: LIST_ALL_PATIENTS,
              payload: data,
            });
          }
        })
        .catch(() => dispatch(patientsListError()));
    } else {
      let copyNextQueryParams = { ...nextQueryParams };
      copyNextQueryParams[`startDate`] = momentWrapper().startOf(`month`).toISOString();
      copyNextQueryParams[`endDate`] = momentWrapper().toISOString();
      // user._practiceId

      const source = axios.CancelToken.source()

      dispatch(saveListCanTok(source))
      postRequest(LIST_ALL_PATIENTS_URL, copyNextQueryParams, {
        cancelToken: source.token
      }).then((res) => {
        if (scrollingParams) {
          let previousData = patientsList?.data?.patients;
          let latestData = res?.data?.data;
          let concatenatedPatients = previousData.concat(latestData);
          const copyPatientResponseObj = {};
          copyPatientResponseObj[`patients`] = concatenatedPatients;
          copyPatientResponseObj[`pager`] = res?.data?.pager;

          dispatch({
            type: LIST_ALL_PATIENTS,
            payload: copyPatientResponseObj,
          });
        } else {
          const data = {};
          data[`patients`] = res?.data?.data;
          data[`pager`] = res?.data?.pager;

          dispatch({
            type: LIST_ALL_PATIENTS,
            payload: data,
          });
        }
      })
        .catch(() => dispatch(patientsListError()));

    }
  };
};
export const messagesApiCall = (id, cancelToken) => {
  return (dispatch) => {
    if (id) {
      dispatch({ type: `MSGAPICALL`, payLoad: { loading: true, error: false } });

      getRequest(PATIENT_ALL_TEXT_MESSAGES_URL + id + `?pageSize=10&pageNumber=${1}`, { cancelToken })
        .then((res) => {
          dispatch({ type: `MSGAPICALL`, payLoad: { loading: false, error: false } });
          if (res.data.messages.length) dispatch(setAllMessages(res.data.messages.reverse()));
        })
        .catch(() => {
          dispatch({ type: `MSGAPICALL`, payLoad: { loading: false, error: true } });
        });
    }
  };
};
export const logsApiCall = (reportId, patientId) => {
  let user = store.getState().user?.user;
  printInBrowser({ key: `userCheck`, value: user });
  return (dispatch) => {
    const selectedPatientIdFromReport = patientId || store.getState()?.patient.patientReport?.patient?._id;
    if (selectedPatientIdFromReport) {
      let url = REPORT_NOTES + selectedPatientIdFromReport + `?reportId=${reportId}`;
      dispatch({ type: `LOGFLAG`, payLoad: { loadingCall: true, error: false } });
      if (!user?.isBiller) {
        getRequest(url)
          .then((response) => {
            dispatch({ type: `LOGFLAG`, payLoad: { loadingCall: false, error: false } });
            dispatch(setAllNotes(response.data.reportNotes));
          })
          .catch(() => {
            dispatch({ type: `LOGFLAG`, payLoad: { loadingCall: false, error: true } });
          });
      }
    }
  };
};
//  get All smart phrases by dispatching this function
export const handleSelectedPatient = (payload) => {
  return async (dispatch) => {
    const { patient, reportId, location, shouldNotStartTimer = false } = payload;
    const user = store.getState()?.user?.user;

    // Add loader on Patient Data when we save time in database
    dispatch(selectedPatientLoading({ type: SELECTED_PATIENT_LOADING, payload: `` }));

    // update user selected id
    dispatch(setSelectedPatientReport({ id: patient?._id }));

    // get selected user report ID
    const source = axios.CancelToken.source()
    dispatch(saveDetailCanToke(source))
    let reportStatus = await postRequest(`${GETTING_REPORT_STATUS}${patient?._id}?reportId=${reportId}`, {
      reportMonth: location?.reportMonth ? location?.reportMonth : Number(momentWrapper().format(`YYYYMM`)),
    });
    let paramPayload;
    if (reportId) {
      paramPayload = `?provider=true&clinicalUser=true&reportId=${reportId}&timeLogs=true`;
    } else {
      paramPayload = `?provider=true&clinicalUser=true&startDate=${momentWrapper()
        .startOf(`month`)
        .toISOString()}&endDate=${momentWrapper().toISOString()}&timeLogs=true`;
    }

    getRequest(GET_PATIENT_BY_ID_URL + patient?._id + paramPayload, { cancelToken: source.token })
      .then((res) => {
        dispatch(updatePatientDataFlag(false));
        dispatch(setMessagesCurrentPage(1));
        dispatch(setMessagesHasMore(true));
        dispatch({
          type: SELECTED_PATIENT,
          payload: res.data,
        });
        //Adding patient device into patient reports data patient
        const copyPatientReport = { ...patient };
        copyPatientReport[`device`] = res.data?.device;
        // // update user selected id
        dispatch(setSelectedPatientReport({ patient: copyPatientReport, selectedPatientReport: reportStatus?.data?.report }));
        dispatch(logsApiCall(reportId || (reportStatus?.data?.report?.data?._id ? reportStatus?.data?.report?.data?._id : null), false));
        if (momentWrapper().format(`YYYYMM`) <= momentWrapper(location?.endDateOfReport).format(`YYYYMM`)) {
          if (user.isSuperAdmin || user.isProvider || user.isClinicalStaff || !user.isBiller) {
            // Source startTime,endTime and description will be set in reducer
            if (!isDme() && !shouldNotStartTimer) {
              dispatch({
                type: HANDLE_TIMER,
                payload: { toggleButton: true },
                oldPatientState: store.getState()?.patient,
              });
            }
          }
        } else {
          if (user.isSuperAdmin || user.isProvider || user.isClinicalStaff || !user.isBiller) {
            dispatch(setHandleTimer(true));
          }
        }
        dispatch(switchPatient(false));
      })
      .catch((err) => {
        dispatch(
          selectedPatientError({
            type: SELECTED_PATIENT_ERROR,
            payload: err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error,
          })
        );
      });
  };
};
export const setPatientsFilters = (filters) => {
  return {
    type: PATIENTS_FILTERS,
    payload: filters,
  };
};
export const setHandleTimer = (payload) => {
  return {
    type: HIDE_TIMER,
    payload: payload,
  };
};
export const setSelectedPatient = (patientId) => {
  return {
    type: SELECTED_PATIENT_UNUSE,
    payload: patientId,
  };
};
// updating audio time logs, audio timer is handle in backend
export const updateAudioTimeLogs = (payload) => {
  return async (dispatch) => {
    const { data, callSuccess, runTimer, leftVoiceMail } = payload;
    if (!runTimer) {
      const patientData = store.getState()?.patient?.patientData;
      const patientsList = store.getState()?.patient?.patientsList;
      const selectedPatient = store.getState()?.patient?.patientData?.data;
      if (data?.duration !== 0) {
        if (callSuccess || leftVoiceMail) {
          const updatePatientDataObject = {
            ...patientData?.data,
            lastMonitoredAt: data?.endDate,
            timeMonitored: patientData?.data?.timeMonitored + data?.duration,
            ...(callSuccess ? { interactiveCommunication: true } : leftVoiceMail && { leftVoiceMail: true }),
          };

          const updatedPatientsListObject = patientsList?.data?.patients?.map((patient) => {
            return patient?._id === selectedPatient?._id
              ? {
                ...patient,
                lastMonitoredAt: data?.endDate,
                timeMonitored: patientData?.data?.timeMonitored + data?.duration,
                ...(callSuccess ? { interactiveCommunication: true } : leftVoiceMail && { leftVoiceMail: true }),
              }
              : patient;
          });

          // updating patient Data on Redux SELECTED_PATIENT (Patient list)
          dispatch({
            type: SELECTED_PATIENT,
            payload: updatePatientDataObject,
          });
          // updating patients lists on redux LIST_ALL_PATIENTS (all patients list)
          dispatch({
            type: LIST_ALL_PATIENTS,
            payload: {
              error: false,
              loading: false,
              success: true,
              patients: updatedPatientsListObject,
            },
          });
          successToast(`Audio Time Log Added`);
        } else {
          const updatePatientDataObject = {
            ...patientData?.data,
            lastMonitoredAt: data?.endDate,
            timeMonitored: patientData?.data?.timeMonitored + data?.duration,
          };

          const updatedPatientsListObject = patientsList?.data?.patients?.map((patient) => {
            return patient?._id === selectedPatient?._id
              ? {
                ...patient,
                lastMonitoredAt: data?.endDate,
                timeMonitored: patient?.timeMonitored + data?.duration,
              }
              : patient;
          });

          // updating patient Data on Redux SELECTED_PATIENT (Patient list)
          dispatch({
            type: SELECTED_PATIENT,
            payload: updatePatientDataObject,
          });
          // updating patients lists on redux LIST_ALL_PATIENTS (all patients list)
          dispatch({
            type: LIST_ALL_PATIENTS,
            payload: {
              error: false,
              loading: false,
              success: true,
              patients: updatedPatientsListObject,
            },
          });
          successToast(`Audio Time Log Added`);
        }
      }
      // Turn on timer if already on
      if (store.getState()?.patient?.timer?.autoTimer === true) {
        dispatch(handleTimer({ status: true, timeEnd: null, videoOrAudio: null, autoTimer: `false` }));
      }
    } else {
      if (store.getState()?.patient?.timer?.autoTimer === true) {
        dispatch(handleTimer({ status: true, timeEnd: null, videoOrAudio: null, autoTimer: `false` }));
      }
    }
  };
};
export const setSelectedPatientReport = (report) => {
  return {
    type: SELECTED_PATIENT_REPORT,
    payload: report,
  };
};
// update patient data, getting latest report status
export const handleUpdatePatientData = (patient) => {
  return async (dispatch) => {
    let reportStatus = await postRequest(`${GETTING_REPORT_STATUS}${patient?._id}?reportId=${null}`, {
      reportMonth: Number(momentWrapper().format(`YYYYMM`)),
    });
    dispatch(setSelectedPatientReport({ patient: { ...patient }, selectedPatientReport: reportStatus?.data?.report }));
  };
};
// Video Status Calling
export const videoCallAction = (payload) => {
  return {
    type: SET_VIDEO_CALL_STATUS,
    payload: payload,
  };
};
// Handle Selected Tab for patient view/past (data)
export const handleSelectedTab = (payload) => {
  return {
    type: HANDLE_SELECTED_TAB,
    payload: payload,
  };
};

export const updatePatientDataFlag = (payLoad) => {
  return {
    type: PATIENT_DATA_FLAG,
    payLoad: payLoad,
  };
};

export const handleReportLoadingFlag = (payload) => {
  return {
    type: REPORT_LOADING,
    payload: payload,
  };
};
export const updateCreatedAt = (newDate) => {
  return (dispatch) => {
    const updatePatientDataObject = {
      ...store.getState()?.patient?.patientData?.data,
      createdAt: newDate,
    };
    dispatch({
      type: SELECTED_PATIENT,
      payload: updatePatientDataObject,
    });
  };
};
// Get selected Patient data from search dashboard
export const handleSelectedPatientFromDashboard = (payload, shouldNotStartTimer) => {
  const {
    user: { user },
  } = store.getState();
  return (dispatch) => {
    const paramPayload = `?startDate=${momentWrapper().startOf(`month`).toISOString()}&endDate=${momentWrapper().toISOString()}&timeLogs=true`;
    const source = axios.CancelToken.source()
    dispatch(saveDetailCanToke(source))
    getRequest(GET_PATIENT_BY_ID_URL + payload + paramPayload, { cancelToken: source.token })
      .then((response) => {
        dispatch(updatePatientDataFlag(false));
        const data = {
          patients: [response.data],
        };

        // Dispatch here
        dispatch({
          type: LIST_ALL_PATIENTS,
          payload: data,
        });
        dispatch(handleSelectedPatient({ patient: response.data, shouldNotStartTimer }));
        if (user?.isBiller)
          dispatch({
            type: HANDLE_SELECTED_TAB,
            payload: `past`,
          });
      })
      .catch(() => { });
  };
};
// Get selected Patient report from dashboard (Ready to sign report chip)
export const handleReportIdFromReadyToSignReport = (payload) => {
  return (dispatch) => {
    let paramPayload;
    const { reportId, reportPatientID, endDateOfReport } = payload;
    if (reportId) {
      paramPayload = `?reportId=${reportId}&timeLogs=true`;
    } else {
      paramPayload = `?startDate="${momentWrapper().startOf(`month`).toISOString()}"&endDate="${momentWrapper().toISOString()}"&timeLogs=true`;
    }
    const source = axios.CancelToken.source()
    dispatch(saveDetailCanToke(source))
    getRequest(GET_PATIENT_BY_ID_URL + reportPatientID + paramPayload, { cancelToken: source.token })
      .then((response) => {
        dispatch(updatePatientDataFlag(false));
        // Dispatch here
        const data = {
          patients: [response.data],
        };
        // Dispatch here
        dispatch({
          type: LIST_ALL_PATIENTS,
          payload: data,
        });
        if (momentWrapper().format(`YYYYMM`) > momentWrapper(endDateOfReport).format(`YYYYMM`)) {
          dispatch(handleSelectedPatient({ patient: response.data, reportId: reportId, location: payload }));
        } else {
          dispatch(handleSelectedPatient({ patient: response.data }));
        }
      })
      .catch(() => { });
  };
};

// Patient Messages
export const setAllMessages = (payload) => {
  return {
    type: SET_MESSAGES,
    payload: payload,
  };
};

export const setMessagesCurrentPage = (payload) => {
  return {
    type: SET_MESSAGES_CURRENT_PAGE,
    payload: payload,
  };
};

export const setMessagesHasMore = (payload) => {
  return {
    type: SET_MESSAGES_HASMORE,
    payload: payload,
  };
};
export const smsResponse = (payLoad) => {
  return {
    type: SMSRESPONSE,
    payLoad: payLoad,
  };
};
export const textError = (payLoad) => {
  return {
    type: TXT_ERROR,
    payLoad: payLoad,
  };
};
//popMessages
export const firstResponsemessage = (payLoad) => {
  return {
    type: FIRST_RESPOSNE,
    payLoad: payLoad,
  };
};
// socketEvent resposne;
export const socketResponse = (payLoad) => {
  return {
    type: SOCKET_RESPONSE,
    payLoad: payLoad,
  };
};
export const communcicationWithPatient = (payLoad) => {
  return {
    type: COMMUNICATION_PATIENT,
    payLoad: payLoad,
  };
};
export const saveListCanTok = (payLoad) => {
  return {
    type: PATIENT_API_CAN_TOK_LIST,
    payLoad: payLoad,
  };
};
export const saveDetailCanToke = (payLoad) => {
  return {
    type: PATIENT_API_CAN_TOK_DETAIL,
    payLoad: payLoad,
  };
};

export const saveActiveConn = (payLoad) => {
  return {
    type: ACTIVE_CONNECTION,
    payLoad: payLoad,
  };
};
export const changeLeftVoiceStatus = (payLoad) => {
  return {
    type: CHANGE_LEFT_VOICE_MAIL_STATUS,
    payLoad: payLoad,
  };
};
// Patient Logs
export const setAllNotes = (payload) => {
  return {
    type: SET_NOTES,
    payload: payload,
  };
};

// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _setSelectedPatient = (patientId) => {
  return (dispatch) => {
    dispatch(setSelectedPatient(patientId));
  };
};

// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _setSelectedPatientReport = (report) => {
  let reportProperties = {};
  if (report) {
    const { _id, reportStatus, ready_to_addendum, isBilled, isSigned, compliant, isAddendumBilled, isAddendumSigned } = report;
    reportProperties[`reportId`] = _id;
    reportProperties[`reportStatus`] = reportStatus;
    reportProperties[`ready_to_addendum`] = ready_to_addendum;
    reportProperties[`isBilled`] = isBilled;
    reportProperties[`isSigned`] = isSigned;
    reportProperties[`compliant`] = compliant;
    reportProperties[`isAddendumBilled`] = isAddendumBilled;
    reportProperties[`isAddendumSigned`] = isAddendumSigned;

    return (dispatch) => {
      dispatch(setSelectedPatientReport(reportProperties));
    };
  } else {
    return (dispatch) => {
      dispatch(setSelectedPatientReport(report));
    };
  }
};
